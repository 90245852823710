import { AuthorityEntity } from './authority.entity';

export class CredentialResponse {
  authenticated: boolean;
  name: string;
  authorities: AuthorityEntity[];
  creatorId: number;
  userId: number;

  static convertToObj(obj: any): CredentialResponse {
    if (obj == null) {
      return null;
    }

    if (obj.errorStatus != undefined) {
      let resp = new CredentialResponse();
      resp.authenticated = false;

      return resp;
    } else {
      let resp = new CredentialResponse();
      resp.name = obj.name;
      resp.authenticated = obj.authenticated;
      resp.authorities = obj.authorities;
      resp.creatorId = obj.credentials.creatorId;
      resp.userId = obj.credentials.userId;

      return resp;
    }
  }
}

export class Credential {
  constructor() {
    this.username = 'admin';
    this.password = 'unidayadminrestservice';
  }

  username: string;
  password: string;
}
