import { ProjectsDto } from './../../projectsDto';
import {
  Component,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ProjectsComponent } from 'src/app/modules/main/components/projects/projects.component';
import { Subject } from 'rxjs';

import { AdminService } from 'src/app/modules/admin/admin-service.service';
import { DialogAction } from '../../common/dialog-action.enum';
import { LocalizationsDto } from '../../localizationsDto';

@Component({
  selector: 'app-dialog-project-new',
  templateUrl: './dialog-project-new.component.html',
  styleUrls: ['./dialog-project-new.component.scss']
})
export class DialogProjectNewComponent implements OnInit, OnDestroy {
  
  editingProject: ProjectsDto;
  localizationsArray:LocalizationsDto[];

  private _pathImgFile: string;
  private _destroyed = new Subject<void>();
  private _fileToUpload: File = null;
  
  public submitted: boolean;

  constructor(
    public dialogRef: MatDialogRef<ProjectsComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private adminService: AdminService
  ) {
    this.submitted = false;
  }

  public get fileToUpload() {
    return this._fileToUpload;
  }

  public set fileToUpload(value: File) {
    this._fileToUpload = value;
  }

  public get pathImgFile() {
    return this._pathImgFile;
  }

  public set pathImgFile(v: string) {
    this._pathImgFile = v;
  }

  ngOnInit(): void {
    this.adminService.getAllLocalizations().subscribe(data => this.localizationsArray = data);
    if (this.data.project.id != null){
      this.editingProject = this.data.project;
    }
    else {
      this.editingProject = new ProjectsDto();
    }
  }

  onNoClick(): void {
    this.dialogRef.close(DialogAction.CLOSE);
  }

  ngOnDestroy() {
    this._destroyed.next();
    this._destroyed.complete();
  }

  save(): void 
  {

    this.submitted = true;
    // this.data.project = this.editingProject;

    if (this.editingProject.id != null) // Диалоговое окно в режиме Edit
    {
      if (this.fileToUpload == null) // Картинка проекта не была изменена
      { 
        this.adminService.addProjectNew(this.editingProject).subscribe(_ => { 
          this.dialogRef.close(DialogAction.SAVE);
        },
          (error) => {
            console.log(error);
          });
      } 
      else // Картинка проекта была изменена
      { 
        this.adminService.changePhotoProject(this.editingProject.id, this.fileToUpload).subscribe(projectDto => {
          this.editingProject.imageFilename = projectDto.imageFilename;
          this.adminService.addProjectNew(this.editingProject).subscribe(_ => { 
              this.dialogRef.close(DialogAction.SAVE);
            },
              (error) => {
                console.log(error);
              });
          },
            (error) => {
              console.log(error);
          });
      }
    } 
    else if (this.editingProject.id == null) // Диалоговое окно в режиме Create
    {
      if (this.fileToUpload != null) // Картинка проекта была добавлена
      {
        this.adminService.addProjectNew(this.editingProject).subscribe(projectDto => { 

        this.editingProject.id = projectDto.id;
        this.adminService.changePhotoProject(this.editingProject.id, this.fileToUpload).subscribe(_ => {
          this.dialogRef.close(DialogAction.SAVE);
        },
        (error) => {
          console.log(error);
        });
        },
          (error) => {
            console.log(error);
        });
      }
      else // Картинка проекта не была добавлена
      {
        this.adminService.addProjectNew(this.editingProject).subscribe(_ => { 
          this.dialogRef.close(DialogAction.SAVE);
          },
            (error) => {
              console.log(error);
          });
      }
    }
  }

  handleFileInput(event: any) {
    var blob = event.target.files[0].slice(0, event.target.files[0].size, 'image/png');
    this.fileToUpload = new File([blob], event.target.files[0].name, {type: 'image/png'});
    this.pathImgFile = URL.createObjectURL(this.fileToUpload);
  }


}
