import { BaseService } from './base.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { ApiUrls } from '../common/api.urls';
import { catchError, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { SessionStorageService } from 'angular-web-storage';
import { CredentialResponse, Credential } from '../models/auth/credential.response.entity';
import { AuthorityEntity } from '../models/auth/authority.entity';
import { SupportDto } from '../models/supportDto';
import { QueryParams } from '../models/queryParams';
import { LANGUAGES } from '../models/common';
import { ROLE } from '../models/auth/role';

@Injectable({
  providedIn: 'root',
})
export class AuthService extends BaseService {
  private loggedIn = new BehaviorSubject<boolean>(false);

  static backUrlName = 'back_url';

  constructor(private router: Router, private http: HttpClient, private sessionStorage: SessionStorageService) {
    super();
    const auth = this.sessionStorage.get('auth');
    this.loggedIn.next(this.isAuthNotEmpty(auth));
  }

  authAsDeveloper(): Observable<any> {
    return this.http.get(ApiUrls.AUTH_AS_DEVELOP, BaseService.httpOptions).pipe(catchError(this.handleError('AUTH_AS_DEVELOP', null)));
  }

  
  private isAuthNotEmpty = (auth: string) => {
    return auth != null && auth != '';
  };

  get isLoggedIn() {
    return this.loggedIn.asObservable();
  }

  get LoggedUser(): CredentialResponse {
    const auth = this.sessionStorage.get('auth');
    if (auth == null || auth == '') {
      return new CredentialResponse();
    }
    return JSON.parse(auth);
  }

  isUser(): boolean {
    return (
      this.LoggedUser.authorities.filter((auth: AuthorityEntity) => {
        return auth.authority == ROLE.PARTNER || auth.authority == ROLE.PERFORMER;
      }).length != 0
    );
  }

  get getCurrentId(): number {
    return this.LoggedUser.creatorId;
  }

  get getUserId(): number {
    return this.LoggedUser.userId;
  }

  static checkAuthUser(auth: CredentialResponse, role: string): boolean {
    let access = false;
    if (auth != null && auth.authorities !== null) {
      auth.authorities.some((el) => {
        access = el.authority === role;
        return access;
      });
    }
    return access;
  }

  static checkSection(url: string, section: string): boolean {
    return url.indexOf(section) == 0;
  }

  authenticate(crdls: Credential, failureHandler) {
    const headers = new HttpHeaders(
      crdls
        ? {
            authorization: 'Basic ' + btoa(crdls.username + ':' + crdls.password),
          }
        : {}
    );
    this.authentication(headers).subscribe((data: CredentialResponse) => {
      if (data != null) {
        this.responseProcessing(data, failureHandler);
      }
    });
  }

  authentication(headers): Observable<any> {
    return this.http.get(ApiUrls.LOGIN, { headers: headers }).pipe(
      tap((data) => console.log('login data:', data)),
      catchError(this.handleLoginError('login error', []))
    );
  }

  private handleLoginError<T>(operation = 'operation', result?: T) {
    ('handleLoginError');
    return (error: any): Observable<T> => {
      if (error.status === 401) {
        this.loggedIn.next(false);
        return of(result as T);
      } else if (error.status == 404) {
        this.loggedIn.next(false);
        // @ts-ignore
        return of({
          errorStatus: error.status,
        });
      }
      return of(result as T);
    };
  }

  private responseProcessing(data, failureHandler) {
    const response: CredentialResponse = CredentialResponse.convertToObj(data);
    if (response.authenticated == true) {
      this.updateAuth(response);
      this.loggedIn.next(true);
      // const backUrl = this.sessionStorage.get(AuthService.backUrlName);
      // if (backUrl == null || backUrl == '') {
      //   AuthService.redirectToAdmin(response);
      // } else {
      //   this.sessionStorage.remove(AuthService.backUrlName);
      //   this.router.navigate([backUrl]);
      // }

      // допилить

      let role = response.authorities[0].authority;

      switch(role) {
        case ROLE.ADMIN:
          AuthService.redirectToAdmin(response);
          break;
        case ROLE.EMPLOYEE:
          AuthService.redirectToEmployee(response);
          break;
        case ROLE.ACCOUNT_MANAGER:
          AuthService.redirectToAccountManager(response);
          break;
      }

      return true;
    }
    // @ts-ignore
    else if (data.errorStatus != null) {
      failureHandler();
    }
  }

  private static redirectToAdmin(credential: CredentialResponse) {
    window.location.href = `${window.location.protocol}//${window.location.host}/admin`;
  }

  private static redirectToEmployee(credential: CredentialResponse) {
    window.location.href = `${window.location.protocol}//${window.location.host}/employee`;
  }

  private static redirectToAccountManager(credential: CredentialResponse) {
    window.location.href = `${window.location.protocol}//${window.location.host}/account_manager`;
  }

  private updateAuth(response: CredentialResponse) {
    this.sessionStorage.set('auth', JSON.stringify(response));
  }

  logoutWithoutRedirect() {
    this.clearLoginData();
    this.http.post(ApiUrls.LOGOUT, {}).subscribe((response) => {});
  }

  logout() {
    this.clearLoginData();
    this.http.post(ApiUrls.LOGOUT, {}).subscribe((response) => {
      this.router.navigateByUrl('/login').then((r) => {});
    });
  }

  clearLoginData() {
    this.loggedIn.next(false);
    this.sessionStorage.remove('auth');
  }

  sendSupportDto(supporDro: SupportDto): Observable<any> {
    return this.http
      .post<SupportDto>(`${ApiUrls.SUPPORT}`, supporDro, BaseService.httpOptions)
      .pipe(catchError(this.handleError('send support dto error', {} as SupportDto)));
  }

  getIPAddress(): Observable<any> {
    return this.http
      .get('http://api.ipify.org/?format=json', BaseService.httpOptions)
      .pipe(catchError(this.handleError('get ip error', {})));
  }

  chooseLanguage(ip): Observable<LANGUAGES> {
    return this.http
      .get<LANGUAGES>(`${ApiUrls.LANGUAGE}/${ip}`, BaseService.httpOptions)
      .pipe(catchError(this.handleError('choose language error', {} as LANGUAGES)));
  }

  sendQueryParams(queryParams: QueryParams): Observable<any> {
    return this.http
      .post<any>(`${ApiUrls.STATISTIC}`, queryParams, BaseService.httpOptions)
      .pipe(catchError(this.handleError('send query params error', {})));
  }
}
