<button mat-stroked-button color="primary" (click)="createVacancy()">Создать вакансию</button>
<mat-table [dataSource]="dataVacancy" class="mat-elevation-z8">
    <ng-container matColumnDef="position">
        <mat-header-cell *matHeaderCellDef> Позиция </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.level}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="description">
        <mat-header-cell *matHeaderCellDef> Описание </mat-header-cell>
        <mat-cell *matCellDef="let element" [innerHTML]="element.description | saveHtml"> </mat-cell>
    </ng-container>

    <ng-container matColumnDef="city">
        <mat-header-cell *matHeaderCellDef> Город </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.city}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="publish">
        <mat-header-cell *matHeaderCellDef> Опубликовано </mat-header-cell>
        <mat-cell *matCellDef="let element">
            <mat-checkbox class="checkboxCell" color="primary" (change)="changePublish(element.id, $event.checked)" [(ngModel)]="element.publish"
                ></mat-checkbox>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="localizationId">
        <mat-header-cell *matHeaderCellDef> Локализация </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.localizationId}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="localizationName">
        <mat-header-cell *matHeaderCellDef> Название локализации </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.localization.displayName}} </mat-cell>
    </ng-container>

    <!-- <ng-container matColumnDef="localizationName">
        <mat-header-cell *matHeaderCellDef> Название локализации </mat-header-cell>
        <mat-header-cell *matHeaderCellDef></mat-header-cell>
        <mat-cell *matCellDef="let element">
            <ng-container *ngFor="let localization of localizationsArray">
                <tr *ngIf="element.localizationId == localization.id">
                  <td>{{localization.displayName}}</td> 
                </tr> 
            </ng-container>
        </mat-cell>
       </ng-container> -->

    <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef></mat-header-cell>
        <mat-cell *matCellDef="let element">
            <button mat-button color="primary" (click)="edit(element.id)">Изменить</button>
            <button mat-button color="warn" (click)="remove(element.id)">Удалить</button>
        </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
</mat-table>