<div class="wrapper forma" [formGroup]="myForm">
    <a id="contact"></a>
    <div class="flex">
        <div class="width_33">
            <h2 class="white_h2" translate>support-request.title</h2>
        </div>
        <div class="width_66">
            <div class="flex">
                <div class="width_50">
                    <input #spy type="text" aria-required="true" aria-invalid="false" [placeholder]="placeholderName"
                        formControlName="username">

                    <div *ngIf=" _username.invalid && (_username.dirty || _username.touched) || submitted">
                        <div class="fieldWarning fixMargin" *ngIf="_username.errors?.required" translate>
                            <span translate>support-request.error.nameLabel.required</span>
                        </div>
                        <div class="fieldWarning fixMargin" *ngIf="_phone.errors?.maxLength" translate>
                            <span translate>support-request.error.nameLabel.maxLength</span>
                        </div>
                    </div>

                </div>
                <div class="width_50">
                    <input type="text" aria-required="true" aria-invalid="false" [placeholder]="placeholderPhone"
                        formControlName="phone">
                    <div class="fieldWarning fixMargin" *ngIf=" _phone.invalid && (_phone.dirty || _phone.touched) || submitted">
                        <div *ngIf="_phone.errors?.required" translate>
                            <span translate>support-request.error.phoneLabel.required</span>
                        </div>
                        <div class="fieldWarning fixMargin" *ngIf="_phone.errors?.pattern" translate>
                            <span translate>support-request.error.phoneLabel.pattern</span>
                        </div>
                    </div>

                </div>
                <div class="width_100">
                    <textarea aria-required="true" aria-invalid="false" [placeholder]="placeholderMessage"
                        formControlName="message" required></textarea>
                    <div *ngIf=" _message.invalid && (_message.dirty || _message.touched) || submitted">
                        <div class="fieldWarning fixMargin" *ngIf="_message.errors?.required" translate>
                            <span translate>support-request.error.messageLabel.required</span>
                        </div>
                    </div>
                </div>
                <div class="width_100">
                    <div class="flex">
                        <div class="soglasie">
                            <input type="checkbox" class="checkbox" id="checkbox" [checked]="isAgree"
                                formControlName="agreement" required>
                            <label for="checkbox"><span translate>support-request.agreement.first-section</span>
<!--                                TODO: Implement a feature or remove the link-->
<!--                                <a class="white_link" href="" translate>-->
                                    <span translate>support-request.agreement.second-section</span>
<!--                                </a>-->
                            </label>
                            <div *ngIf=" _agreement.invalid && (_agreement.dirty || _agreement.touched) || submitted">
                                <div class="fieldWarning" *ngIf="_agreement.errors?.required">
                                    <span translate>support-request.error.agreement.required</span>
                                </div>
                            </div>
                        </div>
                        <div class="custom-btn btn-5" (click)='onSend()'>
                            <span class="small" translate>support-request.sendBtn</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-modal-info-result
  [isActive]="activeInfoModal"
  (isActiveChange)="activeInfoModal=$event"
  [heading]="modalHeading"
  [infoMessage]="resposeMessage">
</app-modal-info-result>
