import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'saveHtml'
})
export class SaveHtmlPipe implements PipeTransform {
  constructor(private sanitizer:DomSanitizer){}

  transform(value: string, args?: any): any {   
    if (value == null) { // test for null or undefined
      return "";
    }  
    value = value.replace(/\n+?/g, "<br>");
    return this.sanitizer.bypassSecurityTrustHtml(value);
    // return this.sanitizer.bypassSecurityTrustStyle(style);
    // return this.sanitizer.bypassSecurityTrustXxx(style); - see docs
  }

}
