<div class = "modal-result-info" *ngIf = "isActive" >
    <div class="modal-result-info-block">
        <div class="close" (click) = "close()">x</div>
        <div class="modal-result-info-content">            
            <h2 translate>{{ heading }}</h2>
            <div class="response-content">
                <p>{{ infoMessage }}</p> 
            </div>
        </div>
    </div>
    
    
</div>
