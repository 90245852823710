import { Component, OnInit } from '@angular/core';
declare var $: any;
@Component({
  selector: 'app-second-screen',
  templateUrl: './second-screen.component.html',
  styleUrls: ['./second-screen.component.scss']
})
export class SecondScreenComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    this.jAnimateVisiable();
  }

  private jAnimateVisiable() {
    setTimeout(function (){ $(".service_block").addClass("visible");},1500);
  }

}
