import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './login/login.component';
import { FormsModule, ReactiveFormsModule, FormGroup } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatDialogModule } from '@angular/material/dialog';
import { MatListModule } from '@angular/material/list';
import { HttpClientModule } from '@angular/common/http';
import { CdkAccordionModule } from '@angular/cdk/accordion';
import { MatExpansionModule } from '@angular/material/expansion';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import { BrowserModule } from '@angular/platform-browser';
import { ProgressSpinnerComponent } from './progress-spinner/progress-spinner.component';
import { AdminModule } from '../admin/admin.module';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { removePrefixUnsave } from './remove-prefix-unsave.pipe';
import {MatGridListModule} from '@angular/material/grid-list';
import { FileValueAccessorDirectiveDirective } from './file-value-accessor-directive.directive';
import { SaveHtmlPipe } from './save-html.pipe';



@NgModule({
  declarations: [LoginComponent, ProgressSpinnerComponent, removePrefixUnsave, FileValueAccessorDirectiveDirective, SaveHtmlPipe
    ],
  imports: [
    CommonModule,
    FormsModule,
    MatButtonModule,
    MatIconModule,
    MatCardModule,
    HttpClientModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    FormsModule,
    ReactiveFormsModule,
    
    
  ],
  exports:[LoginComponent, ProgressSpinnerComponent, removePrefixUnsave, FileValueAccessorDirectiveDirective, SaveHtmlPipe],
})
export class KECommonModule { }
