export class UserDto {
    id: number;
    username: string;
    role: string;
    fio:string;
    enabled: boolean;
    password: string;

    constructor(id: number = null, username:string = "", role:string = "", fio:string = "", enabled: boolean = false, password: string = "") {
        
        if (id  == undefined) {
            this.id = null;
           }else{
            this.id = id;
           }
    
           if (username  == undefined) {
            this.username = null;
           }else{
            this.username = username;
           }
    
           if (role  == undefined) {
            this.role = null;
           }else{
            this.role = role;
           }
    
           if (fio  == undefined) {
            this.fio = null;
           }else{
            this.fio = fio;
           }

           if (password == undefined) {
               this.password = null;
           }else {
               this.password = password;
           }
        
        this.id = id;
        this.username = username;
        this.role = role;
        this.fio = fio;
        this.enabled = enabled;
        this.password = password;
    }

    static getInstance(obj: UserDto): UserDto {
        if (obj == null) {
            return new UserDto();
        }
                
        const newObj: UserDto = new UserDto(obj.id, obj.username, obj.role, obj.fio, obj.enabled, obj.password);
        return newObj;
    }
}