import { Injectable } from '@angular/core';
import {
  CanActivate,
  CanActivateChild,
  CanLoad,
  Route,
  UrlSegment,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from '@angular/router';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { CredentialResponse } from '../models/auth/credential.response.entity';
import { ROLE } from '../models/auth/role';
import { AuthorityEntity } from 'src/app/models/auth/authority.entity'

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate, CanActivateChild, CanLoad {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.authService.isLoggedIn.pipe(
      take(1),
      map((isLoggedIn: boolean) => {
        if (!isLoggedIn) {
          this.redirectToLogin();
          return false;
        }
        return this.checkUserRole(next);
      })
    );
  }

  checkUserRole(route: ActivatedRouteSnapshot): boolean {
    
    const loggedUser: CredentialResponse = this.authService.LoggedUser;
    const userRole = loggedUser.authorities[0].authority;

    if(route.data.roles.indexOf(userRole) === -1) {
      this.redirectToLogin();
      return false;
    }
    
    return true;
  }

  private redirectToLogin() {
    this.router.navigate(['login']);
  }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    // return this.canActivate(next, state);
    return true;
  }

  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
    return true;
  }
}