<div mat-dialog-content>
    <div class="dialog-container">
        <div class="header-dialog">
            <h2 mat-dialog-title>Добавление пользователя</h2>
            <mat-checkbox type="boolean" [(ngModel)]="editingUser.enabled" color="primary">Сделать активным</mat-checkbox>
            
          </div>

        <div class="content-user" >
         
        
            <mat-form-field appearance="fill">
                <mat-label>Пользовательское имя</mat-label>
                <input matInput type="text" name="level" [(ngModel)]="editingUser.username" />
            </mat-form-field>

            <mat-form-field appearance="fill">
                <mat-label>Роль</mat-label>
                <mat-select [(value)]="editingUser.role" >
                    <mat-option *ngFor="let role of roleArray" [value]="role">
                        {{role}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field appearance="fill">
                <mat-label>ФИО</mat-label>
                <input matInput type="text" name="level" [(ngModel)]="editingUser.fio" />
            </mat-form-field>

            <mat-form-field appearance="fill">
                <mat-label>Пароль</mat-label>
                <input matInput type="password" name="level" [(ngModel)]="editingUser.password" />
            </mat-form-field>

            <mat-form-field appearance="fill">
                <mat-label>Повторите пароль</mat-label>
                <input matInput type="password" name="level" [(ngModel)]="repeatPassword" />
            </mat-form-field>

            <div id="passwordError"></div>

        </div>
    </div>


    <div mat-dialog-actions class="container-buttons">
        
        <button mat-button (click)="onNoClick()" [mat-dialog-close]="null">Отмена</button>
        <button [disabled]="!editingUser" mat-button cdkFocusInitial (click)="save()">Сохранить</button>
    </div>

</div>
