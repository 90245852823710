<button mat-stroked-button color="primary" (click)="createUser()">Создать пользователя</button>
<mat-table [dataSource]="dataAllUsers" class="mat-elevation-z8">

    <ng-container matColumnDef="id">
        <mat-header-cell *matHeaderCellDef> ID </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.id}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="username">
        <mat-header-cell *matHeaderCellDef> USERNAME </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.username}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="role">
        <mat-header-cell *matHeaderCellDef> ROLE </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.role}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="fio">
        <mat-header-cell *matHeaderCellDef> FIO </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.fio}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="enabled">
        <mat-header-cell *matHeaderCellDef> ENABLED </mat-header-cell>
        <mat-cell *matCellDef="let element">
            <mat-checkbox class="checkboxCell" color="primary" 
            (change)="changeEnable(element.id, $event.checked)" 
            [(ngModel)]="element.enabled"
                ></mat-checkbox>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef></mat-header-cell>
        <mat-cell *matCellDef="let element">
            <button mat-button color="primary" (click)="edit(element.id)">Изменить</button>
            <button mat-button color="warn" (click)="remove(element.id)">Удалить</button>
        </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
</mat-table>