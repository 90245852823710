<div class="progress-section" *ngIf="!isLoad">
  <app-progress-spinner></app-progress-spinner>
</div>
<button mat-button color="primary" (click)="createProject()">Создать проект</button>

 <table mat-table [dataSource]="arrayProjectDto" multiTemplateDataRows class="mat-elevation-z8">
   
  <!-- <ng-container matColumnDef="{{column}}" *ngFor="let column of columnsToDisplay; let i = index ">
    <th mat-header-cell *matHeaderCellDef> {{displayedColumns[i]}} </th>
    <td mat-cell *matCellDef="let element"  [ngSwitch]="column">
      <div class="" *ngSwitchCase="'publish'">
        <section class="example-section">
          <mat-checkbox color="primary" class="checkboxCell" [(ngModel)]="element.publish"
            (change)="changePublish(element.id, $event.checked)"></mat-checkbox>
        </section>
      </div>

      <div class="" *ngSwitchCase="'startDate'">
        {{element[column] | date: "dd MMMM yyyy"}}
      </div>

      <div class="" *ngSwitchCase="'endDate'">
        {{element[column] | date: "dd MMMM yyyy"}}
      </div>

      <div class="" *ngSwitchDefault>
        {{element[column]}}
      </div>
    </td>
  </ng-container>  -->

  <ng-container class="" matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef> Название > </th>
    <td mat-cell *matCellDef="let element"> {{element.name}} </td>
  </ng-container>

  <ng-container class="" matColumnDef="startDate">
    <th mat-header-cell *matHeaderCellDef> Дата начала </th>
    <td mat-cell *matCellDef="let element"> {{element.startDate | date: "dd MMMM yyyy"}} </td>
  </ng-container>

  <ng-container class="" matColumnDef="endDate">
    <th mat-header-cell *matHeaderCellDef> Дата окончания </th>
    <td mat-cell *matCellDef="let element"> {{element.endDate | date: "dd MMMM yyyy"}} </td>
  </ng-container>

  <ng-container class="" matColumnDef="publish">
    <section class="example-section">
      <th mat-header-cell *matHeaderCellDef> Опубликовано </th>
      <td mat-cell *matCellDef="let element">
        <mat-checkbox color="primary" class="checkboxCell" [(ngModel)]="element.publish"
          (change)="changePublish(element.id, $event.checked)">
      </mat-checkbox>
      </td>
    </section>
  </ng-container>

  <ng-container class="" matColumnDef="localizationId">
    <th mat-header-cell *matHeaderCellDef> Локализация </th>
    <td mat-cell *matCellDef="let element"> {{element.localizationId}} </td>
  </ng-container>

  <ng-container class="" matColumnDef="localizationName">
    <th mat-header-cell *matHeaderCellDef> Название локализации </th>
    <td mat-cell *matCellDef="let element"> {{element.localization.displayName}} </td>
  </ng-container>

  <ng-container matColumnDef="expandedDetail">
    <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplay.length">
      <div class="element-detail" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
        <div class="additional-content">

           <div class="additional-info">
            <div class="image" *ngIf="element.imageFilename">
              <img src="api/keeneye/projects/{{element.id}}/image" alt="">
            </div>
            <div class="image without-image" *ngIf="!element.imageFilename">
              Нет изображения
            </div>

            <section class="actionsBtn-section">
              <button mat-button color="primary" (click)="edit(element.id)">Изменить</button>
              <button mat-button color="warn" (click)="remove(element.id)">Удалить</button>
              
            </section>
          </div>

          <div class="more-info">
            <div class="first-section">
              <div class="customer">
                <div class="content">
                  {{element.customer}}
                </div>
              </div>

              <div class="period">
                <div class="content">
                  {{element.startDate | date}} - {{element.endDate | date}}
                </div>
              </div>
              
            </div>

            <div class="second-section">
                <div class="name">
                  {{element.name}}
                </div>

              <div class="description">
                <label>Описание: </label>
                <div class="content"  [innerHTML]="element.description | saveHtml">
                </div>

              </div>

            </div>
          </div>
        </div>

      </div>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
  <tr mat-row *matRowDef="let element; columns: columnsToDisplay;" class="example-element-row"
    [class.example-expanded-row]="expandedElement === element"
    (click)="expandedElement = expandedElement === element ? null : element">
  </tr>
  <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
</table>