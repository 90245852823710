import { 
  Component,
  Inject,
  OnDestroy,
  OnInit, } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { AdminService } from '../admin-service.service';
import { DialogAction } from '../../common/dialog-action.enum';
import { VacancyDto } from './../../vacancyDto';
import { LocalizationsDto } from '../../localizationsDto';
import { VacancyComponent } from '../vacancy/vacancy.component';

@Component({
  selector: 'app-dialog-vacancy',
  templateUrl: './dialog-vacancy.component.html',
  styleUrls: ['./dialog-vacancy.component.scss']
})
export class DialogVacancyComponent implements OnInit, OnDestroy {

  cityArray:string[];
  localizationsArray:LocalizationsDto[];

  editingVacancy: VacancyDto;

  private _destroyed = new Subject<void>();
  public submitted: boolean;

  constructor(public dialogRef: MatDialogRef<VacancyComponent>, 
    @Inject(MAT_DIALOG_DATA) private data: any, 
    private adminService: AdminService) {
    this.submitted = false;
    this.cityArray = [
        "Саратов",
        "Воронеж"
    ];
  }

  ngOnInit(): void {
    this.adminService.getAllLocalizations().subscribe(data => this.localizationsArray = data);
    if (this.data.vacancy.id != null){
      this.editingVacancy = this.data.vacancy;
    }
    else {
      this.editingVacancy = new VacancyDto();
    }
  }

  onNoClick(): void {
    this.dialogRef.close(DialogAction.CLOSE);
  }

  ngOnDestroy() {
    this._destroyed.next();
    this._destroyed.complete();
  }

  save(): void {
    this.submitted = true;
    this.data.vacancy = this.editingVacancy;
    this.adminService.addVacancy(this.editingVacancy).subscribe(_ => {
      this.dialogRef.close(DialogAction.SAVE);
    },
    (error) => {
      console.log(error);
    });
  }

}
