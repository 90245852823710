import { Component, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { LocalizationsDto } from '../../localizationsDto';
import { AdminService } from '../admin-service.service';
import { DialogAction } from '../../common/dialog-action.enum';
import { ProgressSpinnerComponent } from '../../common/progress-spinner/progress-spinner.component';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { DialogLocalizationsComponent } from '../dialog-localizations/dialog-localizations.component';
declare var moment: any;

@Component({
  selector: 'app-localizations',
  templateUrl: './localizations.component.html',
  styleUrls: ['./localizations.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})

export class LocalizationsComponent implements OnInit, OnChanges {
  typeDialog =  DialogAction;
  arrayLocalizationsDto: LocalizationsDto[];
  private spinner: ProgressSpinnerComponent;
  isLoad: boolean;
  expandedElement: LocalizationsDto | null;

  displayedColumns: string[] = ["Файл локализации", "Локализация", "Применение"];
  columnsToDisplay: string[] = [ "name", "displayName", "inable"];

  constructor(public dialog: MatDialog, private adminService: AdminService) {

   }

   ngOnInit(): void {
    ("init");
    this.tableInit();
  }

  tableInit(): void {
    this.isLoad = false;
    this.adminService.getAllLocalizations().subscribe(res => {
      this.arrayLocalizationsDto = res;
      console.log(this.arrayLocalizationsDto);
      setTimeout(() => {
        this.isLoad = true;
      }, 1500);
    });
  }

  ngOnChanges(): void {

  }

  openDialog(typeDialog: DialogAction, localizations? : LocalizationsDto): void {

    let localizationsDto: LocalizationsDto;
    if (localizations){
      localizationsDto = localizations;
    }else{
      localizationsDto = new LocalizationsDto();
    }
    
    const dialogRef = this.dialog.open(DialogLocalizationsComponent, {
      width: '100%',
      data: {
        localizations: localizationsDto,
        typeDialog: typeDialog
      }
    });

    dialogRef.afterClosed().subscribe(result => {

    if (result != null)
    {
      this.ngOnInit();
    }
    });
  }

  changeInable(idLocalizations: number, eventChecked: any) {
    this.adminService.changeCheckedLocalizations(idLocalizations, eventChecked).subscribe(_ => this.ngOnInit());
  }

  edit(idLocalizations: number) {
    this.adminService.getLocalizations(idLocalizations).subscribe(res => {
      this.openDialog(DialogAction.EDIT, LocalizationsDto.getInstance(res));
    });
  }
  remove(idLocalizations: number) {
    this.adminService.removeLocalizations(idLocalizations).subscribe(_ => this.ngOnInit(),
      (error) => console.log(error)
    );
  }

  createLocalizations() {
    this.openDialog(DialogAction.CREATE);
  }
}
