import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UserDto } from 'src/app/modules/userDto'
import { AdminService } from '../admin-service.service';
import { DialogUserNewComponent } from '../dialog-user-new/dialog-user-new.component';

@Component({
  selector: 'app-all-users',
  templateUrl: './all-users.component.html',
  styleUrls: ['./all-users.component.scss']
})
export class AllUsersComponent implements OnInit {

  dataAllUsers: UserDto[];

  displayedColumns: string[] = ['id', 'username', 'role', 'fio', 'enabled', 'actions']

  constructor(public dialog: MatDialog, private adminService: AdminService) { }

  ngOnInit(): void {
    this.adminService.getAllUsers().subscribe(res => {
      this.dataAllUsers = res
    });
  }

  createUser() {
    this.openDialog(null);    
  }

  openDialog(user: UserDto) {
    let userDto: UserDto;

    if(user) {
      userDto = user;
    } else {
      userDto = new UserDto();
    }

    const dialogRef = this.dialog.open(DialogUserNewComponent, {
      width: '100%',
      data: {
        user: userDto
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != null)
      {
        this.ngOnInit();
      }
    });
  }

  changeEnable(idUser: number, eventChecked: any) {
    this.adminService.checkEnabledUser(idUser, eventChecked).subscribe(_ => this.ngOnInit(),
    (error) => console.log(error));
  }

  edit (idUser: number) {
    this.adminService.getUser(idUser).subscribe(res => {
      this.openDialog(UserDto.getInstance(res));
    })
  }

  remove(id: number) {
    this.adminService.removeUser(id).subscribe(_ => this.ngOnInit(),
      (error) => console.log(error)   
    );
  }

}
