import { Injectable } from '@angular/core';
import { SupportRequestDto } from "../supportRequestsDto"
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ProjectsDto } from '../projectsDto';
import { ProgressSpinnerComponent } from '../common/progress-spinner/progress-spinner.component';
import { VacancyDto } from '../vacancyDto';
import { LocalizationsDto } from '../localizationsDto';
import { UserDto } from '../userDto';

@Injectable({
  providedIn: 'root'
})
export class AdminService {
  
  supportRequestDto: Array<SupportRequestDto>;

  constructor(private http: HttpClient) {

  }

  getRequsts(): Promise<any> {
    return this.http.get<SupportRequestDto>("api/keeneye/request").toPromise();
  }

  deleteRequest(id: number) {
    return this.http.delete<number>(`api/keeneye/request/${id}`);
  }

  addProject(data: any): Observable<ProjectsDto> {
    return this.http.post<ProjectsDto>("api/keeneye/projects/add/project", data.project);
  }

  addProjectNew(project: ProjectsDto): Observable<ProjectsDto> {
    return this.http.post<ProjectsDto>("api/keeneye/projects/add/project", project);
  }

  addPhotoProject(image: File): Observable<ProjectsDto> {
    const formData: FormData = new FormData();
    formData.append("image", image);
    return this.http.post<ProjectsDto>("api/keeneye/projects/add/image", formData);
  }

  changePhotoProject(projectId: number, image: File) {
    
    const formData: FormData = new FormData();
    formData.append("image", image);
    return this.http.post<ProjectsDto>(`api/keeneye/projects/${projectId}/change/image`, formData);
  }

  initSpinner():ProgressSpinnerComponent  {
    let spinner = new ProgressSpinnerComponent();
    spinner.ngOnInit();
    return spinner;
  }

  destroySpinner(spinner: ProgressSpinnerComponent): void {
    spinner.ngOnDestroy();
  }


  getAllProject(): Observable<ProjectsDto[]> {
    return this.http.get<ProjectsDto[]>("api/keeneye/projects/");
  }

  getProject(idProject: number): Observable<ProjectsDto> {
    return this.http.get<ProjectsDto>(`api/keeneye/projects/${idProject}`);
  }

  changeCheckedProject(idProject: number, eventCheck: any): Observable<ProjectsDto> {
    (eventCheck);
    return this.http.post<ProjectsDto>(`api/keeneye/projects/${idProject}/change/publish`, {}, {
      params: {
        eventChecked: eventCheck
      }
    });
  }
  
  removeProject(idProject: number): Observable<ProjectsDto> {
    return this.http.delete<ProjectsDto>(`api/keeneye/projects/${idProject}`);
  }




  addVacancy(vacancy: VacancyDto): Observable<VacancyDto> {
    return this.http.post<VacancyDto>("api/keeneye/vacancy/add", vacancy);
  }

  getAllVacancy(): Observable<VacancyDto[]>  {
    return this.http.get<VacancyDto[]>("api/keeneye/vacancy");
  }


  changeCheckedVacancy(id: number, eventCheck: any): Observable<VacancyDto> {
    (eventCheck);
    return this.http.post<VacancyDto>(`api/keeneye/vacancy/${id}/change/publish`, {}, {
      params: {
        eventChecked: eventCheck
      }
    });
  }

  getVacancy(id: number): Observable<VacancyDto> {
    return this.http.get<VacancyDto>(`api/keeneye/vacancy/${id}`);
  }

  removeVacancy(id: number): Observable<VacancyDto> {
    return this.http.delete<VacancyDto>(`api/keeneye/vacancy/${id}`);
  }

  getPublishedVacancy(): Observable<VacancyDto[]> {
    return this.http.get<VacancyDto[]>(`api/keeneye/vacancy/published`);
  }






  addLocalizations(data: any): Observable<LocalizationsDto> {
    
    return this.http.post<LocalizationsDto>("api/keeneye/localizations/add/localization", data.localizations);
  }

  addPhotoLocalizations(image: File): Observable<LocalizationsDto> {
    const formData: FormData = new FormData();
    formData.append("image", image);
    return this.http.post<LocalizationsDto>("api/keeneye/localizations/add/image", formData);
  }

  changePhotoLocalizations(localizationsId: number, image: File) {
    
    const formData: FormData = new FormData();
    formData.append("image", image);
    return this.http.post<LocalizationsDto>(`api/keeneye/localizations/${localizationsId}/change/image`, formData);
  }

  getAllLocalizations(): Observable<LocalizationsDto[]> {
    return this.http.get<LocalizationsDto[]>("api/keeneye/localizations/");
  }

  getLocalizations(idLocalizations: number): Observable<LocalizationsDto> {
    return this.http.get<LocalizationsDto>(`api/keeneye/localizations/${idLocalizations}`);
  }

  changeCheckedLocalizations(idLocalizations: number, eventCheck: any): Observable<LocalizationsDto> {
    (eventCheck);
    return this.http.post<LocalizationsDto>(`api/keeneye/localizations/${idLocalizations}/change/inable`, {}, {
      params: {
        eventChecked: eventCheck
      }
    });
  }

  removeLocalizations(idLocalizations: number): Observable<LocalizationsDto> {
    return this.http.delete<LocalizationsDto>(`api/keeneye/localizations/${idLocalizations}`);
  }

  getImageLocalization(idLocalizations: number): Observable<LocalizationsDto> {
    return this.http.get<LocalizationsDto>(`api/keeneye/localizations/${idLocalizations}/image`);
  }




  getAllUsers(): Observable<UserDto[]> {
    return this.http.get<UserDto[]>("api/keeneye/all_users");
  }

  addUser(user: UserDto): Observable<UserDto> {
    let password = btoa(user.password);
    user.password = password;
    console.log("BTOA: " + password);
    
    return this.http.post<UserDto>("api/keeneye/all_users/add_user", user);
  }

  checkEnabledUser(id: number, eventCheck: any): Observable<UserDto> {
    (eventCheck);
    return this.http.post<UserDto>(`api/keeneye/all_users/${id}/change`, {}, {
      params: {
        eventChecked: eventCheck
      }
    });
  }

  getUser(id: number): Observable<UserDto> {
    return this.http.get<UserDto>(`api/keeneye/all_users/${id}`);
  }

  removeUser(id: number): Observable<UserDto> {
    return this.http.delete<UserDto>(`api/keeneye/all_users/${id}`);
  }

}