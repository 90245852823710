
<div class = "time_block" >

    <mat-form-field class="example-form-field" appearance="fill">
        <mat-label>Выберите дату</mat-label>
        <input matInput [matDatepicker]="picker" class = "font-size" disabled [(ngModel)]="timesheetComp.date">
        <mat-hint>DD/MM/YYYY</mat-hint>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker disabled="false"></mat-datepicker>
    </mat-form-field>
    
    <mat-form-field class="example-form-field" appearance="fill">
        <mat-label>Выберите время</mat-label>
        <mat-hint>Начало рабочего дня</mat-hint>
        <input matInput type="time" class = "font-size" [(ngModel)]="timesheetComp.startTime">
    </mat-form-field>
    
    <mat-form-field class="example-form-field" appearance="fill">
        <mat-label>Выберите время</mat-label>
        <mat-hint>Конец рабочего дня</mat-hint>
        <input matInput type="time" class = "font-size" [(ngModel)]="timesheetComp.endTime">
    </mat-form-field>
    
    <mat-form-field class="example-form-field" appearance="fill">
        <mat-label>Выберите время</mat-label>
        <mat-hint>Перерыв</mat-hint>
        <input matInput type="time" class = "font-size" [(ngModel)]="timesheetComp.breakTime">
    </mat-form-field>

</div>


<mat-form-field class="example-full-width" appearance="fill">
    <mat-label>Комментарий к работе</mat-label>
    <textarea matInput placeholder="Исправление багов" (input)="timesheetComp.comment = $event.target.value;" matTextareaAutosize matAutosizeMinRows=5 matAutosizeMaxRows=5></textarea>
</mat-form-field>
    
<div class="example-full-width">
    <button mat-stroked-button color="primary" style="width: 100%; height: 50px;" (click)="addTimesheet(timesheetComp)">Добавить отчет</button>
</div> 


<mat-table [dataSource]="timesheet" class="mat-elevation-z8">

    <ng-container matColumnDef="id">
        <mat-header-cell *matHeaderCellDef> ID </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.id}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="date">
        <mat-header-cell *matHeaderCellDef> DATE </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.date}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="startTime">
        <mat-header-cell *matHeaderCellDef> START_TIME </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.startTime}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="endTime">
        <mat-header-cell *matHeaderCellDef> END_TIME </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.endTime}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="break">
        <mat-header-cell *matHeaderCellDef> BREAK </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.breakTime}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="comment">
        <mat-header-cell *matHeaderCellDef> COMMENT </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.comment}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef></mat-header-cell>
        <mat-cell *matCellDef="let element">
            <button mat-button color="primary" (click)="edit(element.id)">Изменить</button>
            <button mat-button color="warn" (click)="remove(element.id)">Удалить</button>
        </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
</mat-table>





