<div class="mat-typography">

    <mat-toolbar color="primary">
        <mat-toolbar-row>
            <button mat-icon-button class="example-icon" aria-label="Example icon-button with menu icon"
                (click)="drawer.toggle()">
                <mat-icon>menu</mat-icon>
            </button>
            <span class="example-spacer"></span>
            <button mat-button routerLink="/" routerLinkActive="active-link">Главная</button>

            <button mat-button routerLink='index' routerLinkActive="active-link">Панель рабочего</button>


        </mat-toolbar-row>


    </mat-toolbar>
</div>
<mat-drawer-container class="example-container">
    <mat-drawer #drawer class="example-sidenav" mode="side">
        <mat-selection-list #shoes [multiple]="false">
            <mat-list-option routerLink="timesheet">
                Рабочий график
            </mat-list-option>
            <mat-list-option class="" >
                Проекты
            </mat-list-option>
            <mat-list-option class="" >
                Задачи
            </mat-list-option>  
        </mat-selection-list>
    </mat-drawer>

        <router-outlet></router-outlet>



</mat-drawer-container>


