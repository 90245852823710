import { Component, OnInit } from '@angular/core';
import { MainService } from '../../services/main.service';

@Component({
  selector: 'app-third-screen',
  templateUrl: './third-screen.component.html',
  styleUrls: ['./third-screen.component.scss']
})
export class ThirdScreenComponent implements OnInit {

  constructor(private mainService: MainService) {
    this.mainService.selectLangSubject.subscribe(_ => this.ngOnInit());
  }

  ngOnInit(): void {
  }

}
