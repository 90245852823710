export class LocalizationsDto {
    
    id: number;
    name: string;
    displayName: string;
    imageFilename: string;
    inable: boolean;

    constructor(id: number = null, name: string = "", displayName: string = "", imageFilename: string ="", inable: boolean = false) {

        if (id  == undefined) {
        this.id = null;
       } else {
        this.id = id;
       }

       if (name  == undefined) {
        this.name = null;
       } else {
        this.name = name;
       }

       if (displayName  == undefined) {
        this.displayName = null;
       } else {
        this.displayName = displayName;
       }

       if (imageFilename  == undefined) {
        this.imageFilename = null;
       } else {
        this.imageFilename = imageFilename;
       }

        this.id = id;
        this.name = name;
        this.displayName = displayName;
        this.imageFilename = imageFilename;
        this.inable = inable;
    }

    static getInstance(obj: LocalizationsDto): LocalizationsDto {
        if (obj == null) {
            return new LocalizationsDto();
        }

        const newObj: LocalizationsDto = new LocalizationsDto(obj.id, obj.name, obj.displayName, obj.imageFilename, obj.inable);
        return newObj;
    }

}
