import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TimesheetDto } from '../timesheetDto';

@Injectable({
  providedIn: 'root'
})
export class EmployeeService {

  constructor(private http: HttpClient) { 

  }

  addTimesheet(timesheet :TimesheetDto): Observable<TimesheetDto> {
    return this.http.post<TimesheetDto>("api/keeneye/timesheet/add", timesheet);
  }

  getTimesheets(username: string): Observable<TimesheetDto[]> {
    return this.http.get<TimesheetDto[]>(`api/keeneye/timesheet/${username}`);
  }

}
