import { Component, OnInit, OnChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ProjectsDto } from '../../projectsDto';
import { AdminService } from '../admin-service.service';
import { DialogAction } from '../../common/dialog-action.enum';
import { ProgressSpinnerComponent } from '../../common/progress-spinner/progress-spinner.component';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { DialogProjectNewComponent } from '../dialog-project-new/dialog-project-new.component';
import { LocalizationsDto } from '../../localizationsDto';
declare var moment: any;

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})

export class ProjectsComponent implements OnInit, OnChanges {
  typeDialog =  DialogAction;
  arrayProjectDto: ProjectsDto[];
  private spinner: ProgressSpinnerComponent;
  isLoad: boolean;
  expandedElement: ProjectsDto | null;

  columnsToDisplay: string[] = [ 'name', 'startDate', 'endDate', 'publish', 'localizationId', 'localizationName'];

  constructor(public dialog: MatDialog, private adminService: AdminService) { 

  }

  ngOnInit(): void {
    ("init");
    this.tableInit();
  }

  tableInit(): void {
    this.isLoad = false;
    this.adminService.getAllProject().subscribe(res => {
      this.arrayProjectDto = res;
      setTimeout(() => {
        this.isLoad = true;
      }, 1500);
    });
  }

  ngOnChanges(): void {}

  openDialog(project? : ProjectsDto): void {
    
    let projectDto: ProjectsDto;
    if (project){
      projectDto = project;
    }else{
      projectDto = new ProjectsDto();
    }
    
    const dialogRef = this.dialog.open(DialogProjectNewComponent, {
      width: '100%',
      data: {
        project: projectDto
      }
    });

    dialogRef.afterClosed().subscribe(result => {

    if (result != null)
    {
      this.ngOnInit();
    }
    });
  }

  changePublish(idProject: number, eventChecked: any) {
    this.adminService.changeCheckedProject(idProject, eventChecked).subscribe(_ => this.ngOnInit());
  }

  edit(idProject: number){
    this.adminService.getProject(idProject).subscribe(res => {
      this.openDialog(ProjectsDto.getInstance(res));
    });
  }

  remove(idProject: number) {
    this.adminService.removeProject(idProject).subscribe(_ => this.ngOnInit(),
      (error) => console.log(error)
    );
  }

  createProject(){
    this.openDialog(null);
  }
}
