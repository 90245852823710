<div mat-dialog-content>
  <div class="dialog-container">
    <div class="image-project">
      <div class="" *ngIf="editingProject.id == null">
        <div class="image" *ngIf="!pathImgFile">
          Нет изображения
        </div>
        <div class="image" *ngIf="pathImgFile">
          <img [src]="pathImgFile | removePrefixUnsave " alt="изображение проекта">
        </div>
      </div>
      <div class="" *ngIf="editingProject.id != null">
        <div class="image" *ngIf="!pathImgFile && editingProject.imageFilename">
          <img src="api/keeneye/projects/{{editingProject.id}}/image" alt="изображение проекта">
        </div>
        <div class="image" *ngIf="pathImgFile">
          <img [src]="pathImgFile | removePrefixUnsave " alt="изображение проекта">
        </div>
        <div class="image" *ngIf="!pathImgFile && !editingProject.imageFilename">
          Нет изображения
        </div>
      </div>
    </div>

    <div class="content-project">
      <div class="header-dialog">
        <h2 mat-dialog-title>Добавление проекта</h2>
        <mat-checkbox type="boolean" [(ngModel)]="editingProject.publish" color="primary">Опубликовать</mat-checkbox>
        
      </div>
 
      <mat-form-field appearance="fill">
        <mat-label>Название проекта</mat-label>
        <input matInput type="text" [(ngModel)]="editingProject.name"/>
        
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>Заказчик</mat-label>
        <input matInput type="text" name="customer" [(ngModel)]="editingProject.customer" />
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>Описание проекта</mat-label>
        <textarea matInput type="text" name="description" [(ngModel)]="editingProject.description"></textarea>
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>Введите период создания проекта</mat-label>
         <mat-date-range-input  [rangePicker]="picker" name>
           <input matStartDate [(ngModel)]="editingProject.startDate" placeholder="Дата начала" />
          <input matEndDate   [(ngModel)]="editingProject.endDate" placeholder="Дата окончания" />
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
         <mat-date-range-picker #picker></mat-date-range-picker>
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>Локализация</mat-label>
        <mat-select [(value)]="editingProject.localizationId">
            <mat-option *ngFor='let localizations of localizationsArray' [value]="localizations.id">
              {{localizations.displayName}}
            </mat-option>
        </mat-select>
      </mat-form-field>

      <button mat-flat-button color="primary" (click)="uploadImg.click()">
        <div class="" *ngIf="editingProject.id != null">
          Изменить изображение
        </div>
        <div class="" *ngIf="editingProject.id == null">
          Загрузить изображение
        </div>
      </button>

    </div>
  </div>

  <input id="my-input" #uploadImg #file type="file" hidden  id="file"
        (change)="handleFileInput($event)" accept="image/jpeg,image/png"  />
   
  <div mat-dialog-actions class="container-buttons">
    
    <button mat-button (click)="onNoClick()" [mat-dialog-close]="null">Отмена</button>
    <button [disabled]="!editingProject" mat-button (click)="save()">Сохранить</button>
  </div>

</div>



