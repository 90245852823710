import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {FeedbackService} from '../../services/feedback.service';
import {MainService} from '../../services/main.service';

@Component({
  selector: 'app-modal-info-result',
  templateUrl: './modal-info-result.component.html',
  styleUrls: ['./modal-info-result.component.scss']
})
export class ModalInfoResultComponent implements OnInit {

  @Input() public isActive: boolean;
  @Output() public isActiveChange: EventEmitter<boolean>;
  @Input() public infoMessage: string;
  @Input() public heading: string;

  constructor(private feedbackService: FeedbackService, private mainService: MainService) {
    this.isActiveChange = new EventEmitter<boolean>();
    this.mainService.selectLangSubject.subscribe(_ => {
      this.mainService.selectLangSubject.subscribe(_ => {
        this.ngOnInit();
      });
    });
  }

  ngOnInit(): void {

  }

  close() {
    this.isActiveChange.emit(false);
  }

}
