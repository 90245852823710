import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  ɵConsole
} from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatCalendar } from '@angular/material/datepicker';
import { DateAdapter, MAT_DATE_FORMATS, MatDateFormats } from '@angular/material/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { AdminService } from 'src/app/modules/admin/admin-service.service';
import { DialogAction } from 'src/app/modules/common/dialog-action.enum';
import { LocalizationsDto } from "../../localizationsDto";
import { LocalizationsComponent } from '../localizations/localizations.component';

@Component({
  selector: 'app-dialog-localizations',
  templateUrl: './dialog-localizations.component.html',
  styleUrls: ['./dialog-localizations.component.scss']
})
export class DialogLocalizationsComponent implements OnInit, OnDestroy {

  private _destroyed = new Subject<void>();
  private _pathImgFile: string;
  typeDialog = DialogAction;
  public submitted: boolean;
  localizationsForm: FormGroup;

  private _fileToUpload: File = null;

  constructor(
    private dialogRef: MatDialogRef<LocalizationsComponent>,
    @Inject(MAT_DIALOG_DATA) private _data: any,
    @Inject(MAT_DATE_FORMATS) cdr: ChangeDetectorRef,
    private fb: FormBuilder,
    private adminService: AdminService,
  ) {
    this.submitted = false;
    this.localizationsForm = this.fb.group({

      name: ["", [Validators.required]],
      displayName: [""],
      inable: [""],
      id: [""],
      imageFilename: ["null"]
    });

  }

  public get name() {
    return this.localizationsForm.get('name');
  }

  public get displayName() {
    return this.localizationsForm.get('displayName');
  }

  public get uploadFile() {
    return this.localizationsForm.get('uploadFile');
  }

  public get inable() {
    return this.localizationsForm.get('inable');
  }


  public get fileToUpload() {
    return this._fileToUpload;
  }
  public set fileToUpload(value: File) {
    this._fileToUpload = value;
  }

  public get pathImgFile() {
    return this._pathImgFile;
  }

  public set pathImgFile(v: string) {
    this._pathImgFile = v;
  }

  public get data() {
    return this._data;
  }

  ngOnInit(): void {

    this.localizationsForm.setValue(this.data.localizations);

  }

  ngOnDestroy() {
    this._destroyed.next();
    this._destroyed.complete();
    this.fileToUpload = null;
  }

  onNoClick(): void {
    this.dialogRef.close(DialogAction.CLOSE);
  }

  save(): void {
    this.submitted = true;
    this.data.localizations = this.localizationsForm.value;

    if (this.localizationsForm.valid) {
      
      if (this.data.typeDialog == DialogAction.EDIT && this.fileToUpload == null) {
        // Диалоговое окно в режиме Edit и картинка проекта была не изменена
        this.adminService.addLocalizations(this.data).subscribe(_ => { 
          this.dialogRef.close(DialogAction.SAVE);
        },
          (error) => {
            console.log(error);
          });
      } else if (this.data.typeDialog == DialogAction.EDIT && this.fileToUpload != null) {
        
        // Диалоговое окно в режиме Edit и картинка проекта была изменена
        this.adminService.changePhotoLocalizations(this.data.localizations.id, this.fileToUpload).subscribe(localizationsDto => {
          this.data.localizations.imageFilename = localizationsDto.imageFilename;
          this.adminService.addLocalizations(this.data).subscribe(_ => { 
            this.dialogRef.close(DialogAction.SAVE);
          },
            (error) => {
              console.log(error);
            });
        },
          (error) => {
            console.log(error);
          });
      }
      else if (this.fileToUpload != null) {
        // Диалоговое окно в режиме Create и картинка проекта была добавлена
        this.adminService.addPhotoLocalizations(this.fileToUpload).subscribe(localizationsDto => {
          this.data.localizations.id = localizationsDto.id;
          this.data.localizations.imageFilename = localizationsDto.imageFilename;
      
          this.adminService.addLocalizations(this.data).subscribe(_ => { 
            this.dialogRef.close(DialogAction.SAVE);
          },
            (error) => {
              console.log(error);
            });
        },
          (error) => {
            console.log(error);
          });
      } else {
        // Диалоговое окно в режиме Create и картинка проекта была не изменена
        this.adminService.addLocalizations(this.data).subscribe(_ => { 
          this.dialogRef.close(DialogAction.SAVE);
        },
          (error) => {
            console.log(error);
          });
      }
    } else {
      console.log(this.localizationsForm.valid);
    }
  }

  handleFileInput(event: any) {
    var blob = event.target.files[0].slice(0, event.target.files[0].size, 'image/png');
    this.fileToUpload = new File([blob], event.target.files[0].name, {type: 'image/png'});
    this.pathImgFile = URL.createObjectURL(this.fileToUpload);
    console.log("ererewrew", this.pathImgFile);
    
  }
  
}
