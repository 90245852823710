export class TimesheetDto {

    id: number;
    username: string;
    date: Date;
    startTime: string;
    endTime: string;
    breakTime: string;
    comment: string;
    fio: string;

}