import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { AdminComponent } from './admin/admin.component';
import { SupportRequestsComponent } from '../admin/support-requests/support-requests.component';
import { ProjectsComponent } from './projects/projects.component';
import { VacancyComponent } from './vacancy/vacancy.component';
import { LocalizationsComponent } from './localizations/localizations.component';
import { AllUsersComponent } from './all-users/all-users.component';

const route: Routes = [
  {
    path: '', component: AdminComponent, children: [
      { path: 'index', redirectTo: 'supportRequests' },
      { path: '', redirectTo: 'supportRequests' },
      { path: 'supportRequests', component: SupportRequestsComponent },
      { path: 'projects', component: ProjectsComponent },
      { path: 'vacancy', component: VacancyComponent },
      { path: 'localizations', component: LocalizationsComponent },
      { path: 'all_users', component: AllUsersComponent}
    ]
  }
];

@NgModule({
  declarations: [],
  imports: [
    CommonModule, RouterModule.forChild(route)
  ]
})
export class AdminRoutingModule { }
