<div class="progress-section" *ngIf="!isLoad">
	<app-progress-spinner></app-progress-spinner>
  </div>
<div class="dop_screen">
	<div class="wrapper">
		<h1 translate>third-screen.title</h1>
	</div>
</div>

<div class="wrapper">

	<div class="" *ngFor="let project of arrayProjectDto">
		<div class="new_project_block">
			<div class="npb_img " *ngIf="project.imageFilename" >
				<div class="" >
					<img src="api/keeneye/projects/{{project.id}}/image" alt="" class="image-project">
				  </div>
				  
			</div>
			<div class="npb_img without-image" *ngIf="!project.imageFilename" >
				Нет изображения				  
			</div>
			<div class="npb_description">
				<div class="npb_description_top">
					<div class="logo_block">
						{{project.customer}}

					</div>
					<div class="date_block">
						{{project.startDate | date}} - {{project.endDate | date}}
					</div>
				</div>
				<p class="project_name">
					{{project.name}}
				</p>
				<p  [innerHTML]="project.description | saveHtml"></p>
			</div>
		</div>
	</div>


</div>