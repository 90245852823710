import { Component, OnInit } from '@angular/core';
import { TimesheetDto } from 'src/app/modules/timesheetDto'
import { AuthService } from 'src/app/services/auth.service';
import { EmployeeService } from 'src/app/modules/employee/employee-service.service'


@Component({
  selector: 'app-timesheet',
  templateUrl: './timesheet.component.html',
  styleUrls: ['./timesheet.component.scss']
})
export class TimesheetComponent implements OnInit {

  timesheet: TimesheetDto[];

  timesheetComp: TimesheetDto;

  displayedColumns: string[] = ['id', 'date', 'startTime', 'endTime', 'break', 'comment'];


  constructor(private authServise: AuthService, private employeeService: EmployeeService) {
    
  }

  ngOnInit(): void {
    this.comeInUser();
    this.employeeService.getTimesheets(this.authServise.LoggedUser.name).subscribe(res => {
      this.timesheet = res;
    })
  }

  addTimesheet(timesheet: TimesheetDto) {

    //переделать
    // timesheet.start_time = timesheet.start_time + ":00";
    // timesheet.end_time = timesheet.end_time + ":00";
    // timesheet.break_time = timesheet.break_time + ":00";
    // timesheet.start_time = new Date(Number(timesheet.date)+Number(timesheet.start_time.toString().split(":")[0])*3600*1000+Number(timesheet.start_time.toString().split(":")[1])*60*1000 );
    // timesheet.end_time = new Date(Number(timesheet.date)+Number(timesheet.end_time.toString().split(":")[0])*3600*1000+Number(timesheet.end_time.toString().split(":")[1])*60*1000 );
    // timesheet.break_time = new Date(Number(timesheet.date)+Number(timesheet.break_time.toString().split(":")[0])*3600*1000+Number(timesheet.break_time.toString().split(":")[1])*60*1000 );
    // console.log(timesheet.start_time.getTime() + "        " + timesheet.end_time.getTime() + "     " + timesheet.break_time);
    
    this.employeeService.addTimesheet(timesheet).subscribe(_ => this.ngOnInit());
  }

  comeInUser() {
    this.timesheetComp = new TimesheetDto();
    this.timesheetComp.username = this.authServise.LoggedUser.name;
  }


}
