import { Component, OnInit } from '@angular/core';
import { MainService } from '../../services/main.service';
import { VacancyDto } from 'src/app/modules/vacancyDto';
declare var $: any;

@Component({
  selector: 'app-vacancies',
  templateUrl: './vacancies.component.html',
  styleUrls: ['./vacancies.component.scss']
})
export class VacanciesComponent implements OnInit {
  
  arrayVacancyDto: VacancyDto[];
  isLoad: boolean
  constructor(private mainService: MainService) {
    this.mainService.selectLangSubject.subscribe(_ => this.ngOnInit());
  }

  ngOnInit(): void {
    window.scrollTo(0, 0);
    this.isLoad = false;
    this.jAnimateVisiable();
    this.mainService.getPublisedVacansyWithLocalization(this.mainService._selectLocal.id).subscribe(res => {
      this.arrayVacancyDto = res;
      this.isLoad = true;
    });
  }

  private jAnimateVisiable() {
    setTimeout(function (){ $(".new_vacancies_block").addClass("visible");},1000);
  }
}
