import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { Language } from '../language.enum';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { catchError, tap } from 'rxjs/operators';
import { ProjectsDto } from '../../projectsDto';
import { VacancyDto } from '../../vacancyDto';
import { LocalizationsDto } from '../../localizationsDto';

@Injectable({
  providedIn: 'root'
})

export class MainService {

  private _selectLangSubject: Subject<Language>;
  private translate: TranslateService;
  public _selectLocal: LocalizationsDto;

  constructor(private translateService: TranslateService, private http: HttpClient) {

    this.translate = translateService;
    this._selectLangSubject = new Subject<Language>();
    this.initLanguage();

  }

  get selectLangSubject() {
    return this._selectLangSubject;
  }

  getLocation(): Language {
    if (navigator.language == "ru-RU") 
    {
      return Language.RU;
    }
    else
    {
      return Language.EN;
    }
  }

  changeLangNew(selectedLocalization: LocalizationsDto): void {

    this._selectLocal = selectedLocalization;
    if (selectedLocalization.name == 'RU') 
    {
      this.selectLangSubject.next(Language.RU);
      this.translateService.use(selectedLocalization.name.toLowerCase());
      localStorage["language"] = Language.RU;
    }
    else if (selectedLocalization.name == 'EN')
    {
      this.selectLangSubject.next(Language.EN);
      this.translateService.use(selectedLocalization.name.toLowerCase());
      localStorage["language"] = Language.EN;
    }
  }

  getCurrentLanguage(): Language {
    if (localStorage["language"] == null) 
    {
      return this.getLocation();
    }
    return localStorage["language"];
  }

  getTranslateService(): TranslateService {
    return this.translate;
  }

  private initLanguage(): void {

    if (localStorage["language"] != null) 
    {
      if (localStorage["language"] == Language.EN) 
      {
        this.translateService.setDefaultLang("en");
        localStorage["language"] = Language.EN;
      } 
      else 
      {
        this.translateService.setDefaultLang("ru");
        localStorage["language"] = Language.RU;
      }

    } else 
    {
      if (this.getLocation() == Language.EN) 
      {
        this.translateService.setDefaultLang("en");
      } 
      else 
      {
        this.translateService.setDefaultLang("ru");
      }

    }
  }

  // getPublisedProjects(): Observable<ProjectsDto[]> {
  //   return this.http.get<ProjectsDto[]>("api/keeneye/projects/published");
  // }

  // getPublisedVacansy(): Observable<VacancyDto[]> {
  //   return this.http.get<VacancyDto[]>("api/keeneye/vacancy/published");
  // }

  getPublisedProjectsWithLocalization(idActiveLocal: number): Observable<ProjectsDto[]> {
    return this.http.get<ProjectsDto[]>(`api/keeneye/projects/${idActiveLocal}/published`);
  }

  getPublisedVacansyWithLocalization(idActiveLocal: number): Observable<VacancyDto[]> {
    return this.http.get<VacancyDto[]>(`api/keeneye/vacancy/${idActiveLocal}/published`);
  }

  getInableLocalizations(): Observable<LocalizationsDto[]> {
    return this.http.get<LocalizationsDto[]>("api/keeneye/localizations/inable");
  }

  
}
