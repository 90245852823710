import { HttpClient, HttpHandler, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TimesheetDto } from '../timesheetDto';

@Injectable({
  providedIn: 'root'
})
export class AccountManagerService {

  constructor(private http: HttpClient) { 
    
  }

  httpOption = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json; ',
    }),responseType: 'text' as 'json'
  }

  //application/vnd.ms-excel

  getAllTimesheets(): Observable<TimesheetDto[]> {
    return this.http.get<TimesheetDto[]>("api/keeneye/timesheet/account_manager_timesheets");
  }

  authenticate(): Observable<string> {
    return this.http.get<string>("api/keeneye/authorizeGoogle/ask", this.httpOption);
  }

  downloadExcelFile(timesheet: TimesheetDto[]): Observable<any>  {
    return this.http.post<any>("api/keeneye/timesheet/download_excel_file", timesheet, { responseType: 'blob' as 'json'});
  }
} 
