import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MainRoutingModule } from './routing.module';
import { HomeComponent } from './components/home/home.component';
import { LayoutComponent } from './components/layout/layout.component';
import { HeaderComponent } from './components/header/header.component';
import { FirstScreenComponent } from './components/first-screen/first-screen.component';
import { SecondScreenComponent } from './components/second-screen/second-screen.component';
import { ThirdScreenComponent } from './components/third-screen/third-screen.component';
import { FormComponent } from './components/form/form.component';
import { FooterComponent } from './components/footer/footer.component';
import { FormsModule, ReactiveFormsModule, FormControl }   from '@angular/forms';
import { HttpClient, HttpParams, HttpClientModule, HttpClientJsonpModule } from '@angular/common/http'
import { ModalInfoResultComponent } from './components/modal-info-result/modal-info-result.component';
import { FeedbackService } from './services/feedback.service';
import { MainService } from './services/main.service';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {MatButtonModule} from '@angular/material/button';
import { ProjectsComponent } from './components/projects/projects.component';
import { VacanciesComponent } from './components/vacancies/vacancies.component';
import {MatFormFieldModule} from '@angular/material/form-field';
import { KECommonModule } from '../common/ke.common.module';
import { MatSelectModule } from '@angular/material/select';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [HomeComponent, LayoutComponent, HeaderComponent, FirstScreenComponent,
     SecondScreenComponent, ThirdScreenComponent, FormComponent, 
     FooterComponent, ModalInfoResultComponent, ProjectsComponent, VacanciesComponent
    ],
  imports: [
    CommonModule, RouterModule, MainRoutingModule, FormsModule, ReactiveFormsModule, 
    MatButtonModule, HttpClientJsonpModule, MatFormFieldModule, KECommonModule, MatSelectModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    })
  ],
  providers: [FeedbackService, MainService]
})
export class MainModule { }
