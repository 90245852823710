import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { AccountManagerComponent } from './account-manager/account-manager.component';
import { TimesheetsComponent } from './timesheets/timesheets.component';

const route: Routes = [
  {
    path: '', component: AccountManagerComponent, children: [
      { path: 'timesheets', component: TimesheetsComponent }
    ]
  }
];

@NgModule({
  declarations: [],
  imports: [
    CommonModule, RouterModule.forChild(route)
  ]
})
export class AccountManagerRoutingModule { }
