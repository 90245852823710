<div class="progress-section" *ngIf="!isLoad">
    <app-progress-spinner></app-progress-spinner>
  </div>
  <button mat-button color="primary" (click)="createLocalizations()">Создать локализацию</button>
  
  <mat-table [dataSource]="arrayLocalizationsDto" multiTemplateDataRows class="mat-elevation-z8">
    <ng-container matColumnDef="{{column}}" *ngFor="let column of columnsToDisplay; let i = index ">
      <mat-header-cell *matHeaderCellDef> {{displayedColumns[i]}} </mat-header-cell>
      <mat-cell *matCellDef="let element"  [ngSwitch]="column">
        <div class="" *ngSwitchCase="'inable'">
          <section class="example-section">
            <mat-checkbox class="checkboxCell" color="primary" [(ngModel)]="element.inable"
              (change)="changeInable(element.id, $event.checked)"></mat-checkbox>
          </section>
        </div>
  

        <div class="" *ngSwitchDefault>
          {{element[column]}}
        </div>
      </mat-cell>
    </ng-container>
  
    <ng-container matColumnDef="expandedDetail">
        <mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplay.length">
          <div class="element-detail" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
            <div class="additional-content">
    
              <div class="additional-info">
                <div class="image" *ngIf="element.imageFilename">
                  <img src="api/keeneye/localizations/{{element.id}}/image" alt="">
                </div>
                <div class="image without-image" *ngIf="!element.imageFilename">
                  Нет изображения
                </div>
    
                <section class="actionsBtn-section">
                  <button mat-button color="primary" (click)="edit(element.id)">Изменить</button>
                  <button mat-button color="warn" (click)="remove(element.id)">Удалить</button>
                  
                </section>
              </div>
    
              <div class="more-info">
                <div class="first-section">
                  <div class="name">
                    <div class="content">
                      {{element.name}}
                    </div>
                  </div>
                  
                </div>
    
                <div class="second-section">
                    <div class="displayName">
                        <label>Локализация: </label>
                        <div class="content"  [innerHTML]="element.displayName | saveHtml">
                        </div>
                    </div>
    
                </div>
              </div>
            </div>
    
          </div>
        </mat-cell>
      </ng-container>
  
    <mat-header-row *matHeaderRowDef="columnsToDisplay"></mat-header-row>
    <mat-row *matRowDef="let element; columns: columnsToDisplay;" class="example-element-row"
      [class.example-expanded-row]="expandedElement === element"
      (click)="expandedElement = expandedElement === element ? null : element">
    </mat-row>
    <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
  </mat-table>
