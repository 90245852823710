import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { MatTableModule } from '@angular/material/table';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatMenuModule } from '@angular/material/menu';
import { MatListModule } from '@angular/material/list';
import { MatCardModule } from '@angular/material/card';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { removePrefixUnsave } from '../common/remove-prefix-unsave.pipe';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { KECommonModule } from '../common/ke.common.module';
import { MatGridListModule } from '@angular/material/grid-list';
import {MatSelectModule} from '@angular/material/select';

import { AdminService } from './admin-service.service';
import { AdminComponent } from './admin/admin.component';
import { AdminRoutingModule } from './admin-routing.module';

import { ProjectsComponent } from './projects/projects.component';
import { DialogProjectNewComponent } from './dialog-project-new/dialog-project-new.component';

import { VacancyComponent } from './vacancy/vacancy.component';
import { DialogVacancyComponent } from './dialog-vacancy/dialog-vacancy.component';

import { SupportRequestsComponent } from '../admin/support-requests/support-requests.component';

import { LocalizationsComponent } from './localizations/localizations.component';
import { DialogLocalizationsComponent } from './dialog-localizations/dialog-localizations.component';
import { AllUsersComponent } from './all-users/all-users.component';
import { DialogUserNewComponent } from './dialog-user-new/dialog-user-new.component';

@NgModule({
  imports: [
    CommonModule, HttpClientModule,
    MatTableModule, MatButtonModule, MatIconModule, MatToolbarModule,
    MatMenuModule, MatListModule, MatCardModule, AdminRoutingModule,
    RouterModule, MatSidenavModule, MatFormFieldModule,
    FormsModule, MatInputModule, KECommonModule, MatCheckboxModule, MatGridListModule,
    FormsModule,
    MatButtonModule,
    MatIconModule,
    MatCardModule,
    HttpClientModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    FormsModule,
    ReactiveFormsModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatCheckboxModule,
    MatSelectModule
    
  ],
  declarations: [AdminComponent, SupportRequestsComponent, ProjectsComponent, VacancyComponent, DialogVacancyComponent, LocalizationsComponent, DialogLocalizationsComponent, DialogProjectNewComponent, AllUsersComponent, DialogUserNewComponent],

  providers: [AdminService, {
    provide: MAT_DATE_LOCALE, useValue: 'ru-RU'
  }]
})
export class AdminModule { }
