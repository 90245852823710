import { Component, OnInit, Input} from '@angular/core';
import { MainService } from '../../services/main.service';
import { LocalizationsDto } from 'src/app/modules/localizationsDto';
import { ThrowStmt } from '@angular/compiler';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  selectedLocal: LocalizationsDto;
  localizationsArray:LocalizationsDto[];

  isLoad: boolean;

  constructor(private mainService: MainService) {
    this.ngOnInit();
  }
  ngOnInit(): void {
    ('localizations init');
    this.localInit();
  }

  localInit(): void {
    window.scrollTo(0, 0);
    this.isLoad = false;
    this.mainService.getInableLocalizations().subscribe(res => {
      this.localizationsArray = res;
      setTimeout(() => {
        for (let local of this.localizationsArray)
        {
          if (local.name == 'RU')
          {
            this.selectedLocal = local;
            this.changeLangNew(this.selectedLocal);
          }
        }
        this.isLoad = true;
      }, 200);
    });
  }

  changeLangNew(selectedLocalization: LocalizationsDto) {
    this.mainService.changeLangNew(selectedLocalization);
  }


}
