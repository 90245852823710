<div class="progress-section" *ngIf="!isLoad">
	<app-progress-spinner></app-progress-spinner>
  </div>
<div class="dop_screen">
	<div class="wrapper">
		<h1 translate>four-screen.title</h1>
	</div>
</div>

<div class="wrapper">

	<div class="" *ngFor="let vacancy of arrayVacancyDto">
		<div class="new_vacancies_block">

			<div class="npb_description">
				<div class="npb_description_top">
					<div class="city_block">
						{{vacancy.city}}

					</div>
					<div class="vacancies_level">
						{{vacancy.level}} 
					</div>
				</div>
				<p  [innerHTML]="vacancy.description | saveHtml"></p>
			</div>
		</div>
	</div>


</div>
  
