export class ApiUrls {
  public static AUTH_AS_DEVELOP = 'api/keeneye/development/authAsUser';
  public static MUSEUMS = 'api/keeneye/museum';
  public static TOURS = 'api/keeneye/tur';
  public static PUBLIC_EXHIBITS = 'api/keeneye/exhibit';
  public static PUBLIC_LOCATIONS = 'api/keeneye/location';
  public static LOGIN = 'api/keeneye/user';
  public static LOGOUT = 'api/keeneye/logout';
  public static SUPPORT = 'api/keeneye/support';
  public static LANGUAGE = 'api/keeneye/language';
  public static STATISTIC = 'api/keeneye/statistic';
  public static RESERVED_HOUSES = 'api/keeneye/appointment/free/house/';
  public static RESERVED_BATH = 'api/keeneye/appointment/free/bath/';
  public static RESERVED_TENT = 'api/keeneye/appointment/free/tent/';
  public static RESERVATION_HOUSES = 'api/keeneye/appointment/occupied/house';
  public static RESERVATION_BATH = 'api/keeneye/appointment/occupied/bath';
  public static RESERVATION_TENT = 'api/keeneye/appointment/occupied/tent';
  public static GET_ALL_HOUSES = 'api/keeneye/appointment/houses';
  public static GET_BATH = 'api/keeneye/appointment/bath';
  public static GET_ALL_TENTS = 'api/keeneye/appointment/tent';
  public static GET_HOUSE_PRICES = 'api/keeneye/appointment/house_description';
  public static GET_RESERVATION_INFO = 'api/keeneye/appointment/';
  public static GET_RESERVATION_DATE = 'api/keeneye/appointment/date/';

  public static GET_HOUSE_INFO = 'api/keeneye/main/info/';
  public static POST_HOUSE_INFO = 'api/keeneye/main/info/update/';
}
