import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BaseService {
  static httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json', Accept: 'application/json;charset=utf-8' }),
  };
  static httpOptionsFile = {
    headers: new HttpHeaders({ 'Content-Type': ' multipart/form-data', Accept: 'application/json;charset=utf-8' }),
  };

  constructor() {}

  protected handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      return of(error);
    };
  }
}
