import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ROLE } from 'src/app/models/auth/role';
import { UserDto } from '../../userDto';
import { DialogAction } from '../../common/dialog-action.enum';
import { AdminService } from '../admin-service.service';
import { AllUsersComponent } from '../all-users/all-users.component';

@Component({
  selector: 'app-dialog-user-new',
  templateUrl: './dialog-user-new.component.html',
  styleUrls: ['./dialog-user-new.component.scss']
})
export class DialogUserNewComponent implements OnInit {

  roleArray: string[];

  editingUser: UserDto;

  repeatPassword: String;

  constructor(public dialogRef: MatDialogRef<AllUsersComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private adminService: AdminService) { 
      this.roleArray = [
        ROLE.ADMIN,
        ROLE.EMPLOYEE,
        ROLE.ACCOUNT_MANAGER
      ]
    }

  ngOnInit(): void {
    if (this.data.user.id != null) {
      this.editingUser = this.data.user;
    }
    else {
      this.editingUser = new UserDto();
    }
    this.repeatPassword = new String();
  }

  onNoClick(): void {
    this.dialogRef.close(DialogAction.CLOSE);
  }

  save(): void {
    if (this.editingUser.password == this.repeatPassword) {
      this.adminService.addUser(this.editingUser).subscribe(_ => {
        this.dialogRef.close(DialogAction.SAVE);
      }, 
      (error) => {
        console.log(error);
      })
    } else {
      var elem = document.getElementById('passwordError');
      elem.innerHTML = 'Пароли не совпадают'
      elem.style.color = 'red'
    }  
  }

}
