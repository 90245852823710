<div mat-dialog-content>
  <div class="dialog-container">
    <div class="image-localizations">
      <div class="" *ngIf="data.typeDialog == typeDialog.CREATE">
        <div class="image" *ngIf="!pathImgFile">
          Нет изображения
        </div>
        <div class="image" *ngIf="pathImgFile">
          <img [src]="pathImgFile | removePrefixUnsave " alt="изображение локализации">
        </div>
      </div>
      <div class="" *ngIf="data.typeDialog == typeDialog.EDIT">
        <div class="image" *ngIf="!pathImgFile && data.localizations.imageFilename">
          <img src="api/keeneye/localizations/{{data.localizations.id}}/image" alt="изображение локализации">
        </div>
        <div class="image" *ngIf="pathImgFile">
          <img [src]="pathImgFile | removePrefixUnsave " alt="изображение локализации">
        </div>
        <div class="image" *ngIf="!pathImgFile && !data.localizations.imageFilename">
          Нет изображения
        </div>
      </div>
    </div>
    

    <div class="content-localizations" [formGroup]="localizationsForm" >
      <div class="header-dialog">
        <h2 mat-dialog-title>Добавление локализации</h2>
        <mat-checkbox formControlName = "inable" color="primary">Применить</mat-checkbox>
        
      </div>

      <mat-form-field appearance="fill">
        <mat-label>Файл локализации</mat-label>
        <input matInput  formControlName = "name">

        <div *ngIf="submitted">
          <div *ngIf="name.errors?.required">
              <span translate>support-request.error.agreement.required</span>
          </div>
      </div>
        
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>Локализация</mat-label>
        <input matInput formControlName = "displayName">
      </mat-form-field>
    
     
      <button mat-flat-button color="primary" (click)="uploadImg.click()">
        <div class="" *ngIf="data.typeDialog == typeDialog.EDIT">
          Изменить изображение
        </div>
        <div class="" *ngIf="data.typeDialog == typeDialog.CREATE">
          Загрузить изображение
        </div>
      </button>
    </div>
  </div>
      <input id="my-input" #uploadImg #file type="file" hidden  id="file"
        (change)="handleFileInput($event)"  accept="image/jpeg,image/png"  />

   

  <div mat-dialog-actions class="container-buttons">
    
    <button mat-button (click)="onNoClick()" [mat-dialog-close]="null">Отмена</button>
    <button [disabled] = "!localizationsForm.valid" mat-button cdkFocusInitial (click)="save()">Сохранить</button>
  </div>

</div>
