import { Component, OnInit } from '@angular/core';
import { AdminService } from '../admin-service.service';
import { SupportRequestDto } from '../../supportRequestsDto';
import { async } from 'rxjs/internal/scheduler/async';


@Component({
  selector: 'app-support-requests',
  templateUrl: './support-requests.component.html',
  styleUrls: ['./support-requests.component.scss']
})
export class SupportRequestsComponent implements OnInit {
s:SupportRequestDto;
  supportRequestDtoArray: Array<SupportRequestDto>;
  displayedColumns = ["username", "phone", "message", "date", "delete"];

  constructor(private adminServiceService: AdminService) { }

  ngOnInit(): void {
     this.getRequests();
    //this.supportRequestDto = this.adminServiceService.supportRequestDto;
  }

 async getRequests() {
    this.supportRequestDtoArray = await this.adminServiceService.getRequsts();
  }

  delete(id: number) {
    this.adminServiceService.deleteRequest(id).subscribe(val => this.getRequests());
  }

}
