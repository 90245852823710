import {Component, OnInit, Output, EventEmitter} from '@angular/core';
import {FeedbackService} from '../../services/feedback.service';
import {FormBuilder, FormControl, FormGroup, Validators, AbstractControl} from '@angular/forms';
import {NgForm} from '@angular/forms';
import {SupportRequestDto} from '../../../supportRequestsDto';
import {MainService} from '../../services/main.service';
import {Language} from '../../language.enum';
import dictionaryEN from '../../../../../assets/i18n/en.json';
import dictionaryRU from '../../../../../assets/i18n/ru.json';

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
  providers: [FeedbackService]
})
export class FormComponent implements OnInit {
  public isAgree = false;
  unamePattern = /^(\+)?([- (]?\d[- )]?){10,14}$/;
  myForm: FormGroup;
  public submitted;
  supportRequestDto: SupportRequestDto;

  public resposeMessage: string;
  public modalHeading: string;
  public activeInfoModal: boolean;

  public placeholderName: string;
  public placeholderPhone: string;
  public placeholderMessage: string;

  constructor(private feedbackService: FeedbackService, private formBuilder: FormBuilder, private mainService: MainService) {
    this._createForm();
    this.activeInfoModal = false;
    this.submitted = false;


    this.mainService.selectLangSubject.subscribe(lang => {
      this.changeValuePlaceholderElems(lang);
    });
  }

  ngOnInit(): void {
    this.initValuePlaceholderElems();

  }

  onSend(): void {
    this.submitted = true;
    if (this.myForm.valid) {

      this.supportRequestDto = {
        username: this._username.value,
        phone: this._phone.value,
        message: this._message.value
      };

      this.feedbackService.sendData(this.supportRequestDto).subscribe(
        value => {
          if (this.mainService.getCurrentLanguage() === Language.EN) {
            this.resposeMessage = 'Your message has been successfully delivered';
            this.modalHeading = 'Thank you for your request';
          } else if (this.mainService.getCurrentLanguage() === Language.RU) {
            this.resposeMessage = 'Ваше сообщение было успешно доставлено';
            this.modalHeading = 'Спасибо за заявку';
          }
          this.activeInfoModal = true;
        },
        error => {
          // TODO: fix connection to the server and get rid of the mock
          if (this.mainService.getCurrentLanguage() === Language.EN) {
            this.resposeMessage = 'Your message has been successfully delivered';
            this.modalHeading = 'Thank you for your request';
          } else if (this.mainService.getCurrentLanguage() === Language.RU) {
            this.resposeMessage = 'Ваше сообщение было успешно доставлено';
            this.modalHeading = 'Спасибо за заявку';
          }
          setTimeout(() => {
            this.activeInfoModal = true;
          }, 750);
          // this.resposeMessage = 'Error - ' + error.status + '!' +
          //   'statusText:' + error.statusText;
          // this.modalHeading = 'Ошибка';
        });
    }

  }

  private _createForm() {
    this.myForm = this.formBuilder.group({
      username: ['', [Validators.required, Validators.maxLength(30)]],
      phone: ['', [Validators.required, Validators.pattern(this.unamePattern)]],
      message: ['', [Validators.required]],
      agreement: [false, [Validators.required]]
    });
  }

  get _username() {
    return this.myForm.get('username');
  }

  get _phone() {
    return this.myForm.get('phone');
  }

  get _message() {
    return this.myForm.get('message');
  }

  get _agreement() {
    return this.myForm.get('agreement');
  }

  private changeValuePlaceholderElems(lang: Language) {
    if (lang === Language.EN) {
      this.placeholderName = dictionaryEN['support-request']['nameLabel'];
      this.placeholderPhone = dictionaryEN['support-request']['phoneLabel'];
      this.placeholderMessage = dictionaryEN['support-request']['messageLabel'];
    } else if (lang === Language.RU) {
      this.placeholderName = dictionaryRU['support-request']['nameLabel'];
      this.placeholderPhone = dictionaryRU['support-request']['phoneLabel'];
      this.placeholderMessage = dictionaryRU['support-request']['messageLabel'];
    }
  }

  private initValuePlaceholderElems() {
    this.changeValuePlaceholderElems(this.mainService.getCurrentLanguage());

  }
}

