import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccountManagerComponent } from './account-manager/account-manager.component';
import { TimesheetsComponent } from './timesheets/timesheets.component';
import { AccountManagerRoutingModule } from './account-manager-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { MatTableModule } from '@angular/material/table';
import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatMenuModule } from '@angular/material/menu';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import {MatSelectModule} from '@angular/material/select';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatListModule } from '@angular/material/list';
import { MatCardModule } from '@angular/material/card';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';


@NgModule({
  declarations: [AccountManagerComponent, TimesheetsComponent],
  imports: [
    CommonModule, AccountManagerRoutingModule, HttpClientModule,
    MatTableModule, MatButtonModule, MatToolbarModule,
    MatMenuModule, MatFormFieldModule, FormsModule,
    ReactiveFormsModule, MatInputModule, RouterModule,
    MatIconModule, MatSidenavModule,
    MatSelectModule,
    MatGridListModule,
    MatListModule,
    MatCardModule,
    MatDatepickerModule,
    MatNativeDateModule
  ]
})
export class AccountManagerModule { }
