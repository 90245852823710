<div mat-dialog-content>
    <div class="dialog-container">
        <div class="header-dialog">
            <h2 mat-dialog-title>Добавление вакансии</h2>
            <mat-checkbox type="boolean" [(ngModel)]="editingVacancy.publish" color="primary">Опубликовать</mat-checkbox>
            
          </div>

        <div class="content-vacancy" >
         
        
            <mat-form-field appearance="fill">
                <mat-label>Позиция сотрудника</mat-label>
                <input matInput type="text" name="level" [(ngModel)]="editingVacancy.level" />
            </mat-form-field>

            <mat-form-field appearance="fill">
                <mat-label>Город</mat-label>
                <mat-select [(value)]="editingVacancy.city" >
                    <mat-option *ngFor="let city of cityArray" [value]="city">
                        {{city}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field appearance="fill">
                <mat-label>Описание</mat-label>
                <textarea matInput type="text" name="description" [(ngModel)]="editingVacancy.description"></textarea>
            </mat-form-field>

            <mat-form-field appearance="fill">
                <mat-label>Локализация</mat-label>
                    <mat-select [(value)]="editingVacancy.localizationId">
                    <mat-option *ngFor='let localizations of localizationsArray' [value]="localizations.id">
                        {{localizations.displayName}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

        </div>
    </div>


    <div mat-dialog-actions class="container-buttons">
        
        <button mat-button (click)="onNoClick()" [mat-dialog-close]="null">Отмена</button>
        <button [disabled]="!editingVacancy" mat-button cdkFocusInitial (click)="save()">Сохранить</button>
    </div>

</div>