export enum ROLE {
    ADMIN = "ADMIN",
    EMPLOYEE = "EMPLOYEE",
    ACCOUNT_MANAGER = "ACCOUNT_MANAGER",
    PARTNER = "PARTNER",
    PERFORMER = "PERFORMER"
}

export const ROLE_MAPPER = {
    [ROLE.ADMIN]: 'ADMIN',
    [ROLE.EMPLOYEE]: 'EMPLOYEE',
    [ROLE.ACCOUNT_MANAGER]: 'ACCOUNT_MANAGER',
    [ROLE.PARTNER]: 'PARTNER',
    [ROLE.PERFORMER]: 'PERFORMER',
}