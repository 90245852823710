import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainModule } from './modules/main/main.module';
import {AuthGuard} from './services/auth.guard'
import {AdminModule} from './modules/admin/admin.module'
import {LoginComponent} from './modules/common/login/login.component'
import { AccountManagerModule } from './modules/account-manager/account-manager.module';
import { ROLE } from './models/auth/role';
import { EmployeeModule } from './modules/employee/employee.module';

const routes: Routes = [
  {path:'', loadChildren:() => MainModule},
  {path: 'admin', loadChildren: () => AdminModule, canActivate: [AuthGuard], data: {roles: ROLE.ADMIN}},
  {path: 'employee', loadChildren: () => EmployeeModule, canActivate: [AuthGuard], data: {roles: ROLE.EMPLOYEE}},
  {path: 'account_manager', loadChildren: () => AccountManagerModule, canActivate: [AuthGuard], data: {roles: ROLE.ACCOUNT_MANAGER}},
  {path: 'login', component: LoginComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
