import { LocalizationsDto } from './localizationsDto';

export class ProjectsDto {

    publish: boolean ;
    id: number = null;
    name: string;
    description: string;
    customer: string;
    startDate: string;
    endDate: string;
    imageFilename: string;
    localization: LocalizationsDto;
    localizationId: number;

    constructor(id?: number, name: string = "", description: string = "", customer: string = "",
        startDate: string = "", endDate: string = "", imageFilename?: string, publish: boolean = false, localization?: LocalizationsDto, localizationId?: number) {
       
      if (id  == undefined) {
        this.id = null;
       } else {
        this.id = id;
       }
       
       if (startDate  == undefined) {
        this.startDate = null;
       } else {
        this.startDate = startDate;
       }

       if (endDate  == undefined) {
        this.endDate = null;
       } else {
        this.endDate = endDate;
       }

       if (imageFilename  == undefined) {
        this.imageFilename = null;
       } else {
        this.imageFilename = imageFilename;
       }

        this.name = name;
        this.description = description;
        this.customer = customer;
        this.publish = publish;
        this.localization = localization;
        this.localizationId = localizationId;
    }

    static getInstance(obj: ProjectsDto): ProjectsDto {
        if (obj == null) {
            return new ProjectsDto();
        }
        // obj.startDate = new Date(obj.startDate);
        // obj.endDate = new Date(obj.startDate);
        const newObj: ProjectsDto = new ProjectsDto(obj.id, obj.name, obj.description, obj.customer, obj.startDate, obj.endDate, obj.imageFilename, obj.publish, obj.localization, obj.localizationId);
        return newObj;
    }


}


