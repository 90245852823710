<div class="big_wrapper mat-typography">
<!--	<div class="grey_background">-->
<!--		<div class="wrapper">-->
<!--			<h2 translate>third-screen.title</h2>-->
<!--			<div class="projects">-->
<!--				<div class="width-66">-->
<!--					<div class="big_project fns">-->
<!--						<div class="inner">-->
<!--							<div class="inner_content">-->
<!--								<div>-->
<!--									<p translate>third-screen.fns.first-section</p>-->
<!--									<p translate>third-screen.fns.second-section</p>-->
<!--								</div>-->
<!--							</div>-->
<!--						</div>-->
<!--					</div>-->
<!--					<div class="little_project edudo">-->
<!--						<div class="inner">-->
<!--							<div class="inner_content">-->
<!--								<div>-->
<!--									<p translate>third-screen.edudo</p>-->
<!--								</div>-->
<!--							</div>-->
<!--						</div>-->
<!--					</div><div class="little_project x5">-->
<!--						<div class="inner">-->
<!--							<div class="inner_content">-->
<!--								<div>-->
<!--									<p translate>third-screen.x5</p>-->
<!--								</div>-->
<!--							</div>-->
<!--						</div>-->
<!--					</div>-->

<!--					<div class="little_project lab">-->
<!--						<div class="inner">-->
<!--							<div class="inner_content">-->
<!--								<div>-->
<!--									<p translate>third-screen.lab.first-section<br />third-screen.lab.second-section</p>-->
<!--								</div>-->
<!--							</div>-->
<!--						</div>-->
<!--					</div>-->
<!--					<div class="gorizont_project gd">-->
<!--						<div class="inner">-->
<!--							<div class="inner_content">-->
<!--								<div>-->
<!--									<p translate>third-screen.gd.first-section <br/>third-screen.gd.second-section</p>-->
<!--									<p translate>third-screen.gd.third-section <br> third-screen.gd.fourth-section</p>-->
<!--								</div>-->
<!--							</div>-->
<!--						</div>-->
<!--					</div>-->
<!--					<div class="clear"></div>-->
<!--				</div>-->
<!--				<div class="width-33">-->
<!--					<div class="gorizont_project hm">-->
<!--						<div class="inner">-->
<!--							<div class="inner_content">-->
<!--								<div>-->
<!--									<p translate>third-screen.hm</p>-->
<!--								</div>-->
<!--							</div>-->
<!--						</div>-->
<!--					</div>-->

<!--					<div class="big_project min_econom">-->
<!--						<div class="inner">-->
<!--							<div class="inner_content">-->
<!--								<div>-->
<!--									<p translate>third-screen.min-econom.first-section</p>-->
<!--									<p translate>third-screen.min-econom.second-section</p>-->
<!--								</div>-->
<!--							</div>-->
<!--						</div>-->
<!--					</div>-->

<!--					<div class="clear"></div>-->
<!--				</div>-->
<!--			</div>-->
<!--&lt;!&ndash;			TODO: Implement features or remove comments&ndash;&gt;-->
<!--&lt;!&ndash;			<div class="custom-btn btn-5" routerLink="projects">&ndash;&gt;-->
<!--&lt;!&ndash;				<span class="small" translate>third-screen.btn-more</span>&ndash;&gt;-->
<!--&lt;!&ndash;			</div>&ndash;&gt;-->
<!--		</div>-->
<!--	</div>-->
	<div class="blue_grey_background">
		<div class="wrapper">
			<h2 translate>third-screen.our-project-title</h2>
				<img src="/assets/img/econom.png" alt="Министерство экономического развития РФ" />
				<img src="/assets/img/x5.png" alt="X5 Retail Group" />
				<img src="/assets/img/teradata.png" alt="Teradata" />
				<img src="/assets/img/smm.png" alt="SMM Planner" />
				<img src="/assets/img/pmt.png" alt="Передовые мультимедийные технологии" />
				<img src="/assets/img/cmacgm.png" alt="CMA CGM" />
				<img src="/assets/img/fns.png" alt="Федеральная налоговая служба" />
		</div>
	</div>
</div>
