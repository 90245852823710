import { LocalizationsDto } from './localizationsDto';

export class VacancyDto{
    
    id: number;
    city: string;
    level: string;
    description: string;
    publish: boolean;
    localization: LocalizationsDto;
    localizationId: number;

    constructor(id: number = null, city:string = "", level:string = "", description:string = "", publish: boolean = false, localization?: LocalizationsDto, localizationId?: number) {
        
        if (id  == undefined) {
            this.id = null;
           }else{
            this.id = id;
           }
    
           if (city  == undefined) {
            this.city = null;
           }else{
            this.city = city;
           }
    
           if (level  == undefined) {
            this.level = null;
           }else{
            this.level = level;
           }
    
           if (description  == undefined) {
            this.description = null;
           }else{
            this.description = description;
           }
        
        this.id = id;
        this.description = description;
        this.city = city;
        this.publish = publish;
        this.level = level;
        this.localization = localization;
        this.localizationId = localizationId;
    }

    static getInstance(obj: VacancyDto): VacancyDto {
        if (obj == null) {
            return new VacancyDto();
        }
                
        const newObj: VacancyDto = new VacancyDto(obj.id, obj.city, obj.level, obj.description, obj.publish, obj.localization, obj.localizationId);
        return newObj;
    }


}