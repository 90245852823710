import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { ConditionalExpr } from '@angular/compiler';
import { HttpClient } from '@angular/common/http';
import { FormGroup } from '@angular/forms';
import { SupportRequestDto } from '../../supportRequestsDto';

@Injectable({
  providedIn: 'root'
})

export class FeedbackService {
  public isAgreements: boolean;
  public isUsername: boolean;
  public isPhone: boolean;
  public isMessage: boolean;

  constructor(private http: HttpClient) {
  }

  sendData(supportRequestDto: SupportRequestDto) {
    return this.http.post("api/keeneye/request/supportRequest",
    
      supportRequestDto
    )
  }


}
