import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { LayoutComponent } from './components/layout/layout.component';
import { ProjectsComponent } from './components/projects/projects.component';
import { VacanciesComponent } from './components/vacancies/vacancies.component';

const routes: Routes = [
  {
    path:'',component:LayoutComponent,children:[
      {path:'',component: HomeComponent},
      {path: 'projects', component: ProjectsComponent},
      {path: 'vacancies', component: VacanciesComponent}
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class 
MainRoutingModule { }
