import { Component, OnInit } from '@angular/core';
import { MainService } from '../../services/main.service';
import { ProjectsDto } from '../../../projectsDto';
declare var $: any;

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss']
})
export class ProjectsComponent implements OnInit {

  arrayProjectDto : ProjectsDto[];
  isLoad: boolean

  constructor(private mainService: MainService) { 
    this.mainService.selectLangSubject.subscribe(_ => this.ngOnInit());
  }

  ngOnInit(): void {
    window.scrollTo(0, 0);
    this.isLoad = false;
    this.jAnimateVisiable();
    this.mainService.getPublisedProjectsWithLocalization(this.mainService._selectLocal.id).subscribe(res => {
      this.arrayProjectDto = res;
      this.isLoad = true;
    });
  }

  private jAnimateVisiable() {
    setTimeout(function (){ $(".new_project_block").addClass("visible");}, 1000);
  }

}
