
<mat-card>
  <h2>Запросы на расчёты цен</h2>
<mat-table [dataSource]="supportRequestDtoArray" class="mat-elevation-z8">
    <!-- Position Column -->
    <ng-container matColumnDef="username">
      <mat-header-cell *matHeaderCellDef> Имя </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.username}} </mat-cell>
    </ng-container>
  
    <!-- Name Column -->
    <ng-container matColumnDef="phone">
      <mat-header-cell *matHeaderCellDef> Телефон </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.phone}} </mat-cell>
    </ng-container>
  
    <!-- Weight Column -->
    <ng-container matColumnDef="message">
      <mat-header-cell *matHeaderCellDef> Сообщение </mat-header-cell>
      <mat-cell *matCellDef="let element" [innerHTML]="element.message | saveHtml">  </mat-cell>
    </ng-container>

    <ng-container matColumnDef="date">
        <mat-header-cell *matHeaderCellDef> Дата запроса </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.date}} </mat-cell>
      </ng-container>

    <ng-container  matColumnDef="delete">
        <mat-header-cell *matHeaderCellDef>  </mat-header-cell>
        <mat-cell *matCellDef="let element"> 
          <button mat-button color="warn" (click)="delete(element.id)">Удалить</button>
          
        </mat-cell>
      </ng-container>
  
    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
  </mat-table>
</mat-card>