import { Directive } from '@angular/core';

@Directive({
  selector: '[appFileValueAccessorDirective]'
})
export class FileValueAccessorDirectiveDirective {

  constructor() { }

}
