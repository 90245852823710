import { Component, OnInit } from '@angular/core';
import { TimesheetDto } from 'src/app/modules/timesheetDto'
import { AccountManagerService } from 'src/app/modules/account-manager/account-manager.service'
import { MatTableDataSource } from '@angular/material/table';
import { FormControl, FormGroup } from '@angular/forms';
import { FilterSetting } from 'src/app/models/filterSetting'

@Component({
  selector: 'app-timesheets',
  templateUrl: './timesheets.component.html',
  styleUrls: ['./timesheets.component.scss']
})
export class TimesheetsComponent implements OnInit {

  timesheet: MatTableDataSource<TimesheetDto>;

  timesheetComp: TimesheetDto;

  rangeDate: string;

  setting: FilterSetting;

  displayedColumns: string[] = ['id','fioEmployee', 'date', 'startTime', 'endTime', 'break', 'comment'];

  constructor(private accountManagerService: AccountManagerService) { }

  ngOnInit(): void {
    this.accountManagerService.getAllTimesheets().subscribe(res => {
      console.log(res);
      
      this.timesheet = new MatTableDataSource(res);
    })
    this.setting = new FilterSetting();
  }

  authenticate() {
    console.log("Auth Working Normal");
    this.downloadExcelFile();
    // this.accountManagerService.
    // authenticate()
    // .subscribe(res => {
    //   window.location.href = res;
    //   console.log(res);   
    // });
  }

  // applyFilter(event: Event) {
  //   this.setFilterByFio();
  //   const filterValue = (event.target as HTMLInputElement).value;
  //   this.timesheet.filter = filterValue.trim().toLowerCase();
  // }

  // setFilterByFio() {
  //   this.timesheet.filterPredicate = function(data, filter: string): boolean {
  //     return data.fio.toLowerCase().includes(filter);
  //   }
  // }

  // setStartDate(event: MatDatepickerInputEvent<Date>) {
  //   this.rangeDate = event.value.toString(); 
  // }

  // setStartDate(event: MatDatepickerInputEvent<Date>) {
  //   console.log("Дата начала: " + event.value);
  // }

  // setEndDate(event: MatDatepickerInputEvent<Date>) {
  //   console.log("Дата конечная: " + event.value);
  // }

  // setFio(event: Event) {

  // }

  // setEndDate(event: MatDatepickerInputEvent<Date>) {
  //   this.rangeDate += '/' + event.value.toString()
  //   this.setRangeDateFilter();
  //   const filterValue = this.rangeDate;
  //   this.timesheet.filter = filterValue.trim().toLowerCase();
  // }

  // setRangeDateFilter() {
  //   this.timesheet.filterPredicate = function(data, filter: string): boolean {

  //     let startDate = filter.split("/")[0];
  //     let endDate = filter.split("/")[1];

  //     if(new Date(startDate) <= new Date(data.date) &&
  //     new Date(endDate) >= new Date(data.date)) {
  //       return true;
  //     } else {
  //       return false;
  //     }
  //   }
  // }

  filterChain() {
    this.timesheet.filterPredicate = function(data, filter: string): boolean {

      let setting = JSON.parse(filter);

      // фио рабочего не определено, период есть
      if (setting.fio == undefined) {
        if (new Date(setting.startDate) <= new Date(data.date) &&
            new Date(setting.endDate) >= new Date(data.date)) {
              return true;
        } else {
          return false;
        }
      // фио рабочего определено, период есть
      } else if (data.fio.toLowerCase().includes(setting.fio)) {
        if (new Date(setting.startDate) <= new Date(data.date) &&
            new Date(setting.endDate) >= new Date(data.date)) {
              return true;
        } else {
          return false;
        }
      } 
    }
  }

  setFilter(setting: FilterSetting){
    this.filterChain();
    this.timesheet.filter = JSON.stringify(setting)
    // console.log(this.timesheet.filteredData);
  }

  //загрузка таймшитов в excel по выборке
  downloadExcelFile() {
    this.accountManagerService.downloadExcelFile(this.timesheet.filteredData)
    .subscribe(file => {
      if (file) {
        let a = document.createElement("a");
        document.body.appendChild(a);
        const url = window.URL.createObjectURL(file);
        a.href = url;
        a.download = "Timesheet.xls";
        a.click();
        window.URL.revokeObjectURL(url);
      }
    });
  }

  range = new FormGroup({
    start: new FormControl(),
    end: new FormControl(),
  });
}
