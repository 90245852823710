<div class="login-container">
  <mat-card class="login-panel">
    <mat-card-header style="text-align: center;">
      <h2 class="reg_h1">Вход</h2>
    </mat-card-header>
    <mat-card-content>
      <mat-form-field class="example-full-width">
        <mat-label>Логин</mat-label>
        <input matInput class="white-caret-cursor" type="text" [(ngModel)]="credential.username" />
      </mat-form-field>

      <mat-form-field class="example-full-width">
        <mat-label>Пароль</mat-label>
        <input matInput class="white-caret-cursor" type="password" placeholder="Пароль" [(ngModel)]="credential.password" />
      </mat-form-field>

      <div *ngIf="errorAuth" style="margin-bottom: 25px; color: red;">
        Неправильное имя или пароль
      </div>
      <div *ngIf="errorConfEmail" style="margin-bottom: 25px; color: red;">
        Аккаунт с такой почтой уже существует
      </div>

      <button class="orange_btn" (click)="login(null)" mat-raised-button color="primary">
        Войти
      </button>
    </mat-card-content>
  </mat-card>
</div>
