import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { EmployeeComponent } from './employee/employee.component';
import { TimesheetComponent } from './timesheet/timesheet.component';

const route: Routes = [
  {
    path: '', component: EmployeeComponent, children: [
      // { path: 'index', redirectTo: 'timesheet'},
      // { path: '', redirectTo: 'timesheet' },
      { path: 'timesheet', component: TimesheetComponent }
    ]
  }
]

@NgModule({
  declarations: [],
  imports: [
    CommonModule, RouterModule.forChild(route)
  ]
})
export class EmployeeRoutingModule { }
