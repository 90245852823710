import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../services/auth.service';
import { ActivatedRoute } from '@angular/router';
import { Credential } from '../../../models/auth/credential.response.entity';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  credential: Credential;
  errorAuth: boolean;
  errorConfEmail: boolean;
  constructor(private authService: AuthService, private route: ActivatedRoute) {
    this.errorAuth = false;
    this.errorConfEmail = false;
    
  }

  ngOnInit(): void {
    let code = this.route.snapshot.queryParams.code;
    const error = this.route.snapshot.queryParams.error;

    if (error != null) {
      console.error(this.route.snapshot.queryParams.error_description);
    }

    this.authService.clearLoginData();
    this.credential = new Credential();
    this.authService.logoutWithoutRedirect();
  }

  login(event: any) {
    if (event != null) {
      event.preventDefault();
    }
    this.authService.authenticate(this.credential, () => {
      this.errorAuth = true;
    });
  }
}
