import { Component, OnInit } from '@angular/core';
import { DialogVacancyComponent } from '../dialog-vacancy/dialog-vacancy.component';
import { VacancyDto } from '../../vacancyDto';
import { MatDialog } from '@angular/material/dialog';
import { AdminService } from '../admin-service.service';
import { LocalizationsDto } from '../../localizationsDto';

interface Vacancy{
  id: number;
  city: string;
  position: string;
  description: string;
  publish: boolean;
  localizationId: number;
  localizationName: string;
}

@Component({
  selector: 'app-vacancy',
  templateUrl: './vacancy.component.html',
  styleUrls: ['./vacancy.component.scss']
})

export class VacancyComponent implements OnInit {

  dataVacancy: VacancyDto[];
  // localizationsArray:LocalizationsDto[];
  displayedColumns: string[] = ['city', 'position', 'description', 'publish', 'localizationId', 'localizationName', 'actions'];

  constructor(public dialog: MatDialog, private adminService: AdminService) { }

  ngOnInit(): void {
    ("init");
    this.tableInit();
  }

  tableInit(): void {
    this.adminService.getAllVacancy().subscribe(res => {
      this.dataVacancy = res;
    });
    // this.adminService.getAllLocalizations().subscribe(data => this.localizationsArray = data);
  }

  openDialog(vacancy?: VacancyDto): void {

    let vacancyDto: VacancyDto;
    if (vacancy) {
      vacancyDto = vacancy;
    } else {
      vacancyDto = new VacancyDto();
    }

    const dialogRef = this.dialog.open(DialogVacancyComponent, {
      width: '100%',
      data: {
        vacancy: vacancyDto
      }
    });

    dialogRef.afterClosed().subscribe(result => {

      if (result != null)
      {
        this.ngOnInit();
      }
    });
  }


  changePublish(idVacancy: number, eventChecked: any) {
    this.adminService.changeCheckedVacancy(idVacancy, eventChecked).subscribe(_ => this.ngOnInit(),
      (error) => console.log(error));
  }

  edit(idVacancy: number) {
    this.adminService.getVacancy(idVacancy).subscribe(res => {

      this.openDialog(VacancyDto.getInstance(res));
    });
  }

  remove(id: number) {
    this.adminService.removeVacancy(id).subscribe(_ => this.ngOnInit(),
      (error) => console.log(error)
    );
  }

  createVacancy() {
    this.openDialog(null);
  }

}
