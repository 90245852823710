<button mat-stroked-button color="primary" style="width: 100%; height: 50px;" (click)="downloadExcelFile()">Сделать выгрузку</button>

<mat-form-field appearance="standard">
    <mat-label>Фио</mat-label>
    <input matInput placeholder="Ex. ium" #input [(ngModel)]="setting.fio">
</mat-form-field>

<!--(keyup)="applyFilter($event)" 
    (dateInput)="setEndDate($event)"
    (dateInput)="setStartDate($event)"
    -->
<mat-form-field appearance="fill">
    <mat-label>Enter a date range</mat-label>
    <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
      <input matStartDate formControlName="start" placeholder="Start date" [(ngModel)]="setting.startDate">
      <input matEndDate formControlName="end" placeholder="End date" [(ngModel)]="setting.endDate">
    </mat-date-range-input>
    <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>
    <mat-datepicker-toggle matSuffix [for]="picker" ></mat-datepicker-toggle>
    <mat-date-range-picker #picker></mat-date-range-picker>
  
    <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error>
    <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>
</mat-form-field>

<button mat-raised-button (click)="setFilter(setting)">Выбрать</button>

<mat-table [dataSource]="timesheet" class="mat-elevation-z8">

    <ng-container matColumnDef="id">
        <mat-header-cell *matHeaderCellDef> ID </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.id}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="fioEmployee">
        <mat-header-cell *matHeaderCellDef> FIO EMPLOYEE </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.fio}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="date">
        <mat-header-cell *matHeaderCellDef> DATE </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.date}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="startTime">
        <mat-header-cell *matHeaderCellDef> START_TIME </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.startTime}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="endTime">
        <mat-header-cell *matHeaderCellDef> END_TIME </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.endTime}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="break">
        <mat-header-cell *matHeaderCellDef> BREAK </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.breakTime}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="comment">
        <mat-header-cell *matHeaderCellDef> COMMENT </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.comment}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef></mat-header-cell>
        <mat-cell *matCellDef="let element">
            <button mat-button color="primary" (click)="edit(element.id)">Изменить</button>
            <button mat-button color="warn" (click)="remove(element.id)">Удалить</button>
        </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>

    <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
    </tr>
</mat-table>